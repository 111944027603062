import { getRecoil } from "recoil-nexus";
import { buildURLWithParam, extend } from ".";
import { DOMAIN_API_URL } from "../constants";
import { tokenState } from "./recoil/common/state";

export function fetch(
  url,
  params?: {},
  options?: {},
  timeOut?: 10
): Promise<any> {
  let token = getRecoil(tokenState);
  const exOptions = extend(
    {
      credentials: "include",
      headers: token
        ? {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          }
        : {
            "Content-Type": "application/json",
          },
    },
    options
  );

  return window
    .fetch(buildURLWithParam(url, params), exOptions)
    .then(toJson)
    .then(validResp);
}

export const getWithPath = (path, params?: {}, options?: {}, timeOut?: 10) => {
  return fetch(DOMAIN_API_URL + path, params, options, timeOut);
};

export const post = (
  url,
  params = {},
  data: any = {},
  options: any = {},
  timeOut
) => {
  return fetch(
    url,
    params,
    extend(
      {
        body: JSON.stringify(data),
      },
      options
    ),
    timeOut
  );
};

export const postWithPath = (
  path,
  params?: {},
  data?: {},
  options?: {},
  timeOut?: 10
) => {
  return post(
    DOMAIN_API_URL + path,
    params,
    data,
    extend({ method: "POST" }, options),
    timeOut
  );
};

function toJson(resp) {
  if (resp.ok) {
    return resp.json();
  }

  return Promise.reject(resp);
}

const validResp = (resp) => {
  if (!resp || resp.code < 0) {
    return Promise.reject(resp);
  }
  return Promise.resolve(resp);
};
