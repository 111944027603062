import React from "react";
import { QRCodeSVG } from "qrcode.react";
import { isProduction } from "../../../constants";

const QRCode = () => {
  return (
    <QRCodeSVG
      value={
        isProduction
          ? "https://app.nodepay.ai/download"
          : "https://beta-app.nodepay.ai/download"
      }
      size={260}
      bgColor={"#ffffff"}
      fgColor={"#000000"}
      level={"L"}
      imageSettings={{
        src: "https://cdn.nodepay.org/images/2024/10/b376578adb2231094ce98eed50623074.jpg",
        x: undefined,
        y: undefined,
        height: 24,
        width: 24,
        excavate: true,
      }}
    />
  );
};

export default QRCode;
