import { Button, Dropdown, Modal, Spin } from "antd";
import { useEffect, useMemo, useState } from "react";
import { ReactComponent as IconEdit } from "../../../assets/icon/ic-edit.svg";
import { ReactComponent as IconLogout } from "../../../assets/icon/ic-logout.svg";
import { ReactComponent as IconNotVerified } from "../../../assets/icon/ic-not-verified.svg";
import { ReactComponent as IconVerified } from "../../../assets/icon/ic-verified.svg";
import Slider from "react-slick";
import { useRecoilRefresher_UNSTABLE, useRecoilValueLoadable } from "recoil";
import {
  infoSocialState,
  typeViewState,
} from "../../../adapter/recoil/common/state";
import { listUserMedalSelector } from "../../../adapter/recoil/medal/state";
import { walletStatusSelector } from "../../../adapter/recoil/wallet/state";
import AuthService from "../../../adapter/services/auth";
import {
  IconCloseModal,
  IconDotVertical,
  IconHelpSocial,
  IconSocialDiscord,
  IconSocialX,
  IconViewMoreMedal,
} from "../../../assets/icon";
import { ReactComponent as IconCopyWallet } from "../../../assets/icon/ic-copy-black.svg";
import { ImgHexagonBlack, ImgHexagonGreen } from "../../../assets/image";
import { MAP_DISCORD_ROLE, TYPE_DEVICE } from "../../../constants";
import { SOCIAL_PROVIDER, WALLET_STATUS } from "../../../constants/enum";
import ROUTE from "../../../constants/route";
import ModalVerifyEmail from "../../../module/earnings/views/components/ModalVerifyEmail";
import { formatWalletAddress } from "../../../module/earnings/views/components/VerifyWalletConnectionView";
import { handleCopyWalletAddress } from "../../../module/earnings/views/components/WalletConnection";
import ButtonDefault from "../../components/ButtonDefault";
import toastFn from "../../components/ToastNoti";
import { ToolTipHover } from "../../components/TooltipHover";
import { useNavigate } from "react-router-dom";
import "./style.css";
import useCurrentWidth from "../../hook/useCurrentWidth";
import ModalChangePassword from "../ModalChangePassword";
import ModalClaimVoucher from "../ModalClaimVoucher";
import ModalEditEmail from "../ModalEditEmail";
import "./style.css";

interface IModalUserProfile {
  accountInfo: any;
  setIsModalProfileOpen?: any;
  handleLogout: any;
}
const ModalUserProfile = ({
  accountInfo,
  setIsModalProfileOpen,
  handleLogout,
}: IModalUserProfile) => {
  const screenWidth = useCurrentWidth();
  const navigate = useNavigate();
  const infoSocialInfoRecoil: any = useRecoilValueLoadable(infoSocialState);
  const [isModalChangePassOpen, setIsModalChangePassOpen] = useState(false);
  const [isModalClaimVoucher, setIsModalClaimVoucher] = useState(false);
  const [isModalEditEmail, setIsModalEditEmail] = useState(false);
  const refreshSocialInfo = useRecoilRefresher_UNSTABLE(infoSocialState);
  const typeDeviceRecoil: any = useRecoilValueLoadable(typeViewState);
  const listMedalFromSelector: any = useRecoilValueLoadable(
    listUserMedalSelector
  );
  const [isOpenModalVerify, setIsOpenModalVerify] = useState<boolean>(false);
  const walletInfoFromRecoil = useRecoilValueLoadable(walletStatusSelector);

  const walletInfo = useMemo(() => {
    if (walletInfoFromRecoil?.state == "hasValue") {
      return {
        success: true,
        data: walletInfoFromRecoil.contents,
      };
    }
    return undefined;
  }, [walletInfoFromRecoil.state]);

  const typeDevice = useMemo(() => {
    if (typeDeviceRecoil?.state == "hasValue") {
      return typeDeviceRecoil.contents;
    } else return undefined;
  }, [typeDeviceRecoil.state]);

  const infoSocialInfo: any = useMemo(() => {
    if (infoSocialInfoRecoil?.state == "hasValue") {
      return infoSocialInfoRecoil.contents;
    } else return undefined;
  }, [infoSocialInfoRecoil.state]);

  const isVerifiedEmail = useMemo(() => {
    if (infoSocialInfo?.[SOCIAL_PROVIDER.EMAIL]) {
      return true;
    }
    return false;
  }, [infoSocialInfo]);
  const listMedal = useMemo(() => {
    if (listMedalFromSelector?.state == "hasValue") {
      let info = listMedalFromSelector.contents;
      let list = info.list;
      let lengthList = info.total;
      if (lengthList > 5) {
        return [
          ...list,
          {
            isViewMore: true,
            name: `View more (${lengthList * 1 - 5})`,
          },
        ];
      }
      return list;
    }
    return undefined;
  }, [listMedalFromSelector.state]);

  let intervalCountdownDiscord;

  useEffect(() => {
    if (!infoSocialInfo) return;
    infoSocialInfo[SOCIAL_PROVIDER.DISCORD]?.discord_user_achievement
      ?.expired_at &&
      renderCountdownDiscord(
        infoSocialInfo[SOCIAL_PROVIDER.DISCORD].discord_user_achievement
          .expired_at
      );
  }, [infoSocialInfo]);

  useEffect(() => {
    return () => clearInterval(intervalCountdownDiscord);
  }, []);

  const settingSliderCampaign: any = useMemo(() => {
    if (typeDeviceRecoil?.state == "hasValue") {
      return {
        focusOnSelect: true,
        infinite: false,
        slidesToShow:
          screenWidth > 450
            ? typeDevice === TYPE_DEVICE.DESKTOP
              ? 3.1
              : 2.1
            : 1.3,
        slidesToScroll: 2,
        speed: 500,
        arrows: false,
        initialSlide: 0,
        centerPadding: 16,
      };
    } else return {};
  }, [typeDeviceRecoil]);

  const renderSocialX = () => {
    if (!infoSocialInfo) return;
    let infoX = infoSocialInfo?.[SOCIAL_PROVIDER.X];

    if (infoX) {
      return (
        <div className="lg:p-4 p-3 rounded-lg bg-neutral-10 flex justify-between items-center">
          <div className="flex items-center">
            <img src={IconSocialX} alt="ic-discord" className="w-5 h-5 mr-2" />
            <span className="text-16px font-bold">Twitter</span>
            <span className="text-neutral-70" hidden={!infoX?.social_name}>
              <span className="text-16px font-bold">&nbsp;-&nbsp;</span>@
              {infoX.social_name}
            </span>
          </div>
          <Dropdown
            trigger={["hover", "click"]}
            placement="bottomRight"
            menu={{
              items: [
                {
                  label: "Disconnect",
                  key: "1",
                  onClick: () => {
                    AuthService.disconnectX()
                      .then((res) => {
                        if (res.code == 0) {
                          refreshSocialInfo();
                          toastFn({
                            type: "success",
                            message: "Disconnect successfully!",
                          });
                        } else {
                          toastFn({ message: "Error", description: res.msg });
                        }
                      })
                      .catch((error) => {
                        toastFn({
                          message: "Error",
                          description: error?.msg || "Something went error",
                        });
                      });
                  },
                },
              ],
            }}
          >
            <img className="w-5 h-5 cursor-pointer" src={IconDotVertical} />
          </Dropdown>
        </div>
      );
    } else {
      return (
        <div className="lg:p-4 p-3 rounded-lg bg-neutral-10 flex justify-between">
          <div className="flex items-center">
            <img src={IconSocialX} alt="ic-discord" className="w-5 h-5 mr-2" />
            <span className="text-16px font-bold">Twitter</span>
          </div>
          <ButtonDefault
            className="btn-default-small"
            onClick={() => {
              AuthService.connectX()
                .then((res) => {
                  if (res.code == 0 && res?.data?.url) {
                    setIsModalProfileOpen(false);
                    window.open(res?.data?.url, "_blank");
                  } else {
                    toastFn({ message: "Error", description: res.msg });
                  }
                })
                .catch((error) => {
                  toastFn({
                    message: "Error",
                    description: error?.msg || "Something went error",
                  });
                });
            }}
            text="Connect"
          ></ButtonDefault>
        </div>
      );
    }
  };

  const renderCountdownDiscord = (expireAt) => {
    let remainingTime = +new Date(expireAt) - +new Date();

    let countdownElement = document.getElementById("countdown-discord");
    intervalCountdownDiscord = setInterval(() => {
      // Tính toán phút và giây và ngày và giờ
      let dates = Math.floor(remainingTime / (24 * 60 * 60000));
      // console.log("date:::", dates);
      let remainingValue = remainingTime % (24 * 60 * 60000);
      let hours = Math.floor(remainingValue / (60 * 60000));
      // console.log(hours);
      remainingValue %= 60 * 60000;
      let minutes = Math.floor(remainingValue / 60000);
      let seconds = Math.floor((remainingValue % 60000) / 1000);
      // Hiển thị thời gian
      let displayMinutes = minutes < 10 ? "0" + minutes : minutes;
      let displaySeconds = seconds < 10 ? "0" + seconds : seconds;
      let displayHours = hours < 10 ? "0" + hours : hours;
      let displayDates = dates;
      countdownElement.innerHTML = `${displayDates} days ${displayHours}h ${displayMinutes}mn ${displaySeconds}s`;

      // Giảm thời gian còn lại
      remainingTime -= 1000;

      // Kiểm tra nếu hết thời gian
      if (remainingTime < 0) {
        clearInterval(intervalCountdownDiscord);
        countdownElement.innerHTML = "0 days 0h 0mn 0s";
      }
    }, 1000);
  };

  const renderSocialDiscord = () => {
    if (!infoSocialInfo) return;
    let infoDiscord = infoSocialInfo?.[SOCIAL_PROVIDER.DISCORD];
    let infoDiscordAchievement = infoDiscord?.discord_user_achievement;
    let rate = infoDiscordAchievement?.network_earning_rate;
    if (infoDiscord) {
      return (
        <div className="rounded-lg bg-neutral-10 flex flex-col lg:px-4 lg:py-5 p-3 flex-wrap">
          <div className="flex justify-between items-center">
            <div className="flex items-center flex-wrap">
              <img
                src={IconSocialDiscord}
                alt="ic-discord"
                className="w-5 h-5 mr-2"
              />
              <span className="text-16px font-bold">Discord - &nbsp;</span>
              <span className="text-neutral-70">
                @{infoDiscord.social_name}
              </span>
              {infoDiscordAchievement?.discord_role && (
                <div className="h-6 rounded px-2 bg-neutral-110 flex items-center ml-1">
                  <span className="text-white text-12px ml-1">
                    {MAP_DISCORD_ROLE[infoDiscordAchievement.discord_role]}
                  </span>
                  <ToolTipHover
                    iconHover={
                      <img
                        className="cursor-pointer"
                        width={16}
                        height={16}
                        src={IconHelpSocial}
                      />
                    }
                  >
                    <span>Your Discord role in the Nodepay channel</span>
                  </ToolTipHover>
                </div>
              )}
            </div>
            <Dropdown
              trigger={["hover", "click"]}
              placement="bottomRight"
              menu={{
                items: [
                  {
                    label: "Disconnect",
                    key: "1",
                    onClick: () => {
                      AuthService.disconnectDiscord()
                        .then((res) => {
                          if (res.code == 0) {
                            refreshSocialInfo();
                            toastFn({
                              type: "success",
                              message: "Disconnect successfully!",
                            });
                          } else {
                            toastFn({ message: "Error", description: res.msg });
                          }
                        })
                        .catch((error) => {
                          toastFn({
                            message: "Error",
                            description: error?.msg || "Something went error",
                          });
                        });
                    },
                  },
                ],
              }}
            >
              <img className="w-5 h-5 cursor-pointer" src={IconDotVertical} />
            </Dropdown>
          </div>
          {infoDiscordAchievement?.discord_role && (
            <div className="bg-white rounded p-4 mt-3">
              <div className="px-2 h-6 rounded bg-red w-fit min-w-[100px]">
                <span
                  id="countdown-discord"
                  className="text-12px text-white"
                ></span>
              </div>
              <div>
                <div className="mt-2 mb-3">
                  <span className="text-16px font-bold">Role bonus</span>
                </div>
                <div className="w-full flex items-center justify-between">
                  <span className="text-neutral-70 truncate">
                    Network earning rate:
                  </span>
                  {Number(rate) ? (
                    Number(rate) > 1 ? (
                      <div
                        hidden={!rate || Number(rate) == 1}
                        className="min-w-[36px] relative h-[45px] bg-center bg-no-repeat color font-bold text-12px flex items-center justify-center text-white"
                        style={{
                          backgroundImage: `url(${ImgHexagonGreen})`,
                          backgroundSize: "36px 45px",
                        }}
                      >
                        <span
                          className="text-white font-bold absolute bottom-[10px] text-12px"
                          style={{
                            left: "50%",
                            transform: "translateX(-50%)",
                          }}
                        >
                          x{rate}
                        </span>
                      </div>
                    ) : (
                      <div
                        hidden={Number(rate) != 1}
                        className="min-w-[36px] relative h-[45px] bg-center bg-no-repeat color font-bold text-12px flex items-center justify-center text-white"
                        style={{
                          backgroundImage: `url(${ImgHexagonBlack})`,
                          backgroundSize: "36px 45px",
                        }}
                      >
                        <span
                          className="text-white font-bold absolute bottom-[11px] text-12px"
                          style={{
                            left: "50%",
                            transform: "translateX(-50%)",
                          }}
                        >
                          x1
                        </span>
                      </div>
                    )
                  ) : null}
                </div>
                <div className="w-full flex items-center justify-between mt-4">
                  <span className="text-neutral-70 grow">
                    Successful referral bonus (default 100 points/referral)
                  </span>
                  <div className="rounded-full text-white px-3 py-1 font-bold bg-purple shrink-0">
                    + {infoDiscordAchievement?.more_referral_bonus}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      );
    } else
      return (
        <div className="lg:p-4 p-3 rounded-lg bg-neutral-10 flex justify-between">
          <div className="flex items-center">
            <img
              src={IconSocialDiscord}
              alt="ic-discord"
              className="w-5 h-5 mr-2"
            />
            <span className="text-16px font-bold">Discord</span>
          </div>
          <ButtonDefault
            className="btn-default-small"
            onClick={() => {
              AuthService.connectDiscord()
                .then((res) => {
                  if (res.code == 0 && res?.data?.url) {
                    setIsModalProfileOpen(false);
                    window.open(res?.data?.url, "_blank");
                  } else {
                    toastFn({ message: "Error", description: res.msg });
                  }
                })
                .catch((error) => {
                  toastFn({
                    message: "Error",
                    description: error?.msg || "Something went error",
                  });
                });
            }}
            text="Connect"
          ></ButtonDefault>
        </div>
      );
  };

  return (
    <>
      <div className="flex flex-col items-center justify-center p-6">
        <img className="w-[100px] h-[100px] mb-3" src={accountInfo?.avatar} />
        <div className="text-16px font-bold text-neutral-110">
          {accountInfo?.name || "-"}
        </div>
        <div className="flex items-center gap-[2px]">
          <span className="text-neutral-70">{accountInfo?.email || "-"}</span>
          {infoSocialInfo !== undefined && !isVerifiedEmail && (
            <ToolTipHover
              iconHover={
                <IconEdit
                  className="cursor-pointer"
                  onClick={() => {
                    setIsModalProfileOpen(false);
                    setIsModalEditEmail(true);
                  }}
                />
              }
            >
              <div>Change email</div>
            </ToolTipHover>
          )}
        </div>
        {infoSocialInfo !== undefined ? (
          <>
            {isVerifiedEmail && accountInfo?.email ? (
              <div className="mt-2 px-3 h-8 rounded-[24px] bg-[#58CC02] text-white font-bold text-12px flex items-center gap-[2px]">
                <IconVerified />
                Verified
              </div>
            ) : (
              <div className="flex flex-col items-center gap-2">
                <div className="mt-2 px-3 h-8 rounded-[24px] bg-red-light text-red font-bold text-12px flex items-center gap-[2px]">
                  <IconNotVerified />
                  Not Verify
                </div>
                <ButtonDefault
                  className="btn-default-small"
                  onClick={() => {
                    setIsModalProfileOpen(false);
                    setIsOpenModalVerify(true);
                  }}
                  text="Verify now"
                ></ButtonDefault>
              </div>
            )}
          </>
        ) : (
          <>
            <Spin className="mt-3" />
          </>
        )}

        <div className="w-full flex flex-col items-center justify-center lg:mt-5 mt-4 mb-4">
          <Button
            type="text"
            className="w-full flex items-center justify-center"
            size="large"
            onClick={() => {
              setIsModalProfileOpen(false);
              setIsModalClaimVoucher(true);
            }}
          >
            Claim Voucher
          </Button>
          <div className="w-full flex items-center gap-2 mt-4">
            <ButtonDefault
              className="w-1/2"
              onClick={() => window.open("https://nodepay.ai/#blogs", "_blank")}
              text="FAQ"
            ></ButtonDefault>
            <ButtonDefault
              className="w-1/2"
              onClick={() => window.open("https://nodepay.ai/", "_blank")}
              text="About Nodepay"
            ></ButtonDefault>
          </div>
        </div>
        <div className="text-16px text-neutral-70 mb-5">
          If you need help, feel free to hop on our
          <span
            className="text-neutral-110  cursor-pointer"
            onClick={() =>
              window.open("https://discord.com/invite/nodepay", "_blank")
            }
          >
            &nbsp;Discord&nbsp;
          </span>
          server and submit a
          <span className="text-neutral-110">&nbsp;support ticket</span>.
        </div>
        <div className="w-full h-[1px] bg-[#E4E5E9] mb-5"></div>
        <div className="w-full mb-5">
          {walletInfo?.success ? (
            <>
              {walletInfo?.data?.status === WALLET_STATUS.CONNECTED &&
              walletInfo?.data?.wallet_address ? (
                <div className="flex flex-col">
                  <div className="font-bold text-16px">Your Wallet</div>
                  <div className="rounded-lg p-4 bg-neutral-10 flex items-center justify-between mt-4">
                    <div
                      className="flex items-center gap-[2px] cursor-pointer"
                      onClick={() =>
                        handleCopyWalletAddress(
                          walletInfo?.data?.wallet_address
                        )
                      }
                    >
                      {formatWalletAddress(walletInfo?.data?.wallet_address)}
                      <IconCopyWallet />
                    </div>
                    <div className="flex items-center">
                      <div className="rounded-full w-[6px] h-[6px] bg-green mr-1"></div>
                      <span className="text-12px font-bold text-green">
                        Connected
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col rounded-lg px-5 py-3 bg-neutral-10 ">
                  <div className="font-bold text-16px">Your Wallet</div>
                  <div>
                    <div className="mt-2 mb-4 text-16px">
                      <b>Connect your wallet</b> to start exchanging Nodepay
                      points for tokens and enjoy secure transactions.to start
                      exchanging Nodepay points for tokens and enjoy secure
                      transactions.
                    </div>
                    <ButtonDefault
                      className="w-full btn-default-xlarge"
                      onClick={() => {
                        setIsModalProfileOpen(false);
                        navigate(ROUTE.EARNING);
                      }}
                      text="Connect Your Wallet"
                    ></ButtonDefault>
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className="flex items-center justify-center w-full h-20">
              <Spin />
            </div>
          )}
        </div>
        <div className="w-full h-[1px] bg-[#E4E5E9] mb-5"></div>

        <div className="flex flex-col w-full">
          <span className="text-18px font-bold">Social Profiles</span>

          {infoSocialInfo ? (
            <div className="flex flex-col lg:gap-3 gap-4 lg:mt-5 mt-4">
              {renderSocialDiscord()}
              {renderSocialX()}
            </div>
          ) : (
            <Spin />
          )}
        </div>
        <div className="w-full h-[1px] bg-[#E4E5E9] mt-5 mb-5"></div>
        <div className="flex flex-col w-full">
          <div className="flex items-center justify-between">
            <span className="text-18px font-bold">Your medal</span>
            <ButtonDefault
              className="btn-default-small"
              onClick={() => {
                navigate(ROUTE.MEDAL);
                setIsModalProfileOpen(false);
              }}
              text="Get more"
            ></ButtonDefault>
          </div>
          {listMedal ? (
            <>
              {listMedal?.length > 0 ? (
                <div className="rounded-xl mt-4 slider-container slider-medal-user  bg-neutral-10 py-4 pl-4">
                  <Slider {...settingSliderCampaign}>
                    {listMedal?.map((item) => {
                      return (
                        <div>
                          <div
                            onClick={() => {
                              if (item?.isViewMore) {
                                navigate(ROUTE.MEDAL);
                                setIsModalProfileOpen(false);
                              }
                            }}
                            className="aspect-[1/1] p-3 bg-white rounded-xl flex flex-col items-center justify-center"
                          >
                            {item?.isViewMore ? (
                              <img
                                width={48}
                                height={48}
                                src={IconViewMoreMedal}
                                className="object-contain"
                              />
                            ) : (
                              <img
                                height={48}
                                src={item.img_url}
                                className="object-contain aspect-[140/80]"
                              />
                            )}
                            <span className="text-16px text-center mt-4 max-w-full truncate">
                              {item?.name}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </Slider>
                </div>
              ) : (
                <div className="rounded-xl mt-4 mx-auto">
                  You don't have any medal yet
                </div>
              )}
            </>
          ) : (
            <Spin />
          )}
        </div>
        <div className="w-full h-[1px] bg-[#E4E5E9] mt-5 mb-5"></div>

        <div className="h-fit px-3 flex items-center justify-between w-full">
          <span
            className="text-neutral-110 text-16px cursor-pointer"
            onClick={() => {
              setIsModalChangePassOpen(true);
              setIsModalProfileOpen(false);
            }}
          >
            Change Password
          </span>
          <div
            className="flex items-center text-16px cursor-pointer btn-logout"
            onClick={handleLogout}
          >
            <IconLogout />
            Log out
          </div>
        </div>
      </div>
      <Modal
        title="Change Password"
        open={isModalChangePassOpen}
        closeIcon={<img width={24} height={24} src={IconCloseModal} />}
        className="modal-change-password w-[572px] h-[388px]"
        footer={null}
        onCancel={() => {
          setIsModalChangePassOpen(false);
          setIsModalProfileOpen(true);
        }}
      >
        <ModalChangePassword
          setIsModalChangePassOpen={setIsModalChangePassOpen}
          setIsModalProfileOpen={setIsModalProfileOpen}
        />
      </Modal>
      <Modal
        title="Claim Voucher"
        open={isModalClaimVoucher}
        closeIcon={<img width={24} height={24} src={IconCloseModal} />}
        className="modal-change-password w-[572px] h-[388px]"
        footer={null}
        onCancel={() => {
          setIsModalClaimVoucher(false);
          setIsModalProfileOpen(true);
        }}
      >
        <ModalClaimVoucher
          setIsModalClaimVoucher={setIsModalClaimVoucher}
          setIsModalProfileOpen={setIsModalProfileOpen}
        />
      </Modal>
      <Modal
        className="modal-edit-email"
        title="Change email"
        width={524}
        open={isModalEditEmail}
        closeIcon={<img width={24} height={24} src={IconCloseModal} />}
        footer={null}
        onCancel={() => {
          setIsModalEditEmail(false);
          setIsModalProfileOpen(true);
        }}
      >
        <ModalEditEmail
          currentEmail={accountInfo?.email}
          setIsModalEditEmail={setIsModalEditEmail}
          setIsModalProfileOpen={setIsModalProfileOpen}
        />
      </Modal>
      <Modal
        title=""
        open={isOpenModalVerify}
        closeIcon={<img width={24} height={24} src={IconCloseModal} />}
        onCancel={() => {
          setIsOpenModalVerify(false);
          setIsModalProfileOpen(true);
        }}
        footer={null}
        width={572}
      >
        <ModalVerifyEmail email={accountInfo?.email} />
      </Modal>
    </>
  );
};

export default ModalUserProfile;
