import React from "react";
import { TYPE_DEVICE } from "../../../constants";
import { useLocation, useNavigate } from "react-router-dom";
import ROUTE from "../../../constants/route";
import LayoutResponsiveContainer from "../../layout/LayoutResponsiveContainer";
import LayoutContainer from "../../layout";
import { useRecoilState, useSetRecoilState } from "recoil";
import { accountState, tokenState } from "../../../adapter/recoil/common/state";

const ResponsiveView = ({ type, children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [accountInfo, setAccountInfo] = useRecoilState(accountState);
  const setToken = useSetRecoilState(tokenState);

  const renderContent = () => {
    if ([TYPE_DEVICE.MOBILE, TYPE_DEVICE.DESKTOP].includes(type)) {
      if (
        [
          ROUTE.LOGIN,
          ROUTE.REGISTER,
          ROUTE.CREATE_NEW_PASSWORD,
          ROUTE.FORGOT_PASSWORD,
          ROUTE.REGISTER_RESULT,
          ROUTE.VERIFY_EMAIL,
          ROUTE.VERIFY_WALLET,
        ].includes(location?.pathname)
      ) {
        return (
          <div className="w-screen bg-white flex items-center justify-center">
            {children}
          </div>
        );
      } else {
        if (type === TYPE_DEVICE.MOBILE) {
          return (
            <LayoutResponsiveContainer>{children}</LayoutResponsiveContainer>
          );
        }
        if (type === TYPE_DEVICE.DESKTOP) {
          return <LayoutContainer>{children}</LayoutContainer>;
        }
      }
    } else {
      if (
        [
          ROUTE.LOGIN,
          ROUTE.REGISTER,
          ROUTE.CREATE_NEW_PASSWORD,
          ROUTE.FORGOT_PASSWORD,
          ROUTE.REGISTER_RESULT,
          ROUTE.VERIFY_EMAIL,
          ROUTE.VERIFY_WALLET,
        ].includes(location?.pathname)
      ) {
        return (
          <div className="border border-solid border-grey-primary w-[576px]">
            {children}
          </div>
        );
      } else {
        return (
          <div className="border border-solid border-grey-primary max-w-[576px]">
            <LayoutResponsiveContainer>{children}</LayoutResponsiveContainer>
          </div>
        );
      }
    }
  };

  return (
    <div className="w-screen bg-white flex items-center justify-center">
      {renderContent()}
    </div>
  );
};

export default ResponsiveView;
