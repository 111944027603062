import {
  useRecoilRefresher_UNSTABLE,
  useRecoilValue,
  useRecoilValueLoadable,
} from "recoil";
import {
  accountState,
  typeViewState,
} from "../../../../../adapter/recoil/common/state";
import { IconCloseModal } from "../../../../../assets/icon";
import {
  ImgWalletStep1,
  ImgWalletStep2,
  ImgWalletStep3,
} from "../../../../../assets/image";
import WalletStep from "../WalletStep";
import { Modal, Spin } from "antd";
import ModalVerifyEmail from "../ModalVerifyEmail";
import { useMemo, useState } from "react";
import { walletStatusSelector } from "../../../../../adapter/recoil/wallet/state";
import { WALLET_STATUS, WALLET_STEP } from "../../../../../constants/enum";
import WalletConnectionContainer from "../WalletConnectionContainer";
import ModalConfirmEmail from "../ModalConfirmEmail";
import { formatWalletAddress } from "../VerifyWalletConnectionView";
import { ReactComponent as IconCopyWalletWhite } from "../../../../../assets/icon/ic-copy-wallet-address-white.svg";
import { ReactComponent as IconDisconnectWallet } from "../../../../../assets/icon/ic-disconnect-wallet.svg";
import copy from "copy-to-clipboard";
import toastFn from "../../../../../shared/components/ToastNoti";
import WalletService from "../../../../../adapter/services/wallet";
import ModalVerifyStep2 from "../ModalVerifyStep2";
import {
  BaseMessageSignerWalletAdapter,
  WalletAdapterNetwork,
  WalletReadyState,
} from "@solana/wallet-adapter-base";
import {
  ConnectionProvider,
  useWallet,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import {
  BitgetWalletAdapter,
  Coin98WalletAdapter,
  CoinbaseWalletAdapter,
  LedgerWalletAdapter,
  PhantomWalletAdapter,
  SolflareWalletAdapter,
  TorusWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import { clusterApiUrl } from "@solana/web3.js";
import ModalSelectWallet from "../ModalSelectWallet";
import { TYPE_DEVICE } from "../../../../../constants";
import ModalEnterWallet from "../ModalEnterWallet";

export const handleCopyWalletAddress = (wallet_address) => {
  if (!wallet_address) return;
  let result = copy(wallet_address);
  if (result) {
    toastFn({
      type: "success",
      message: (
        <div className="flex flex-col">
          <div className="mb-0.5 text-16px text-neutral-110">
            Wallet address copied!
          </div>
          <div className="text-14px text-neutral-110">{wallet_address}</div>
        </div>
      ),
    });
  } else {
    toastFn({ message: "Error", description: "Something went error" });
  }
};

const WalletConnection = () => {
  const typeDevice = useRecoilValue(typeViewState);
  const network = WalletAdapterNetwork.Mainnet;
  const endpoint = useMemo(() => clusterApiUrl(network), [network]);
  const [isResetState, setIsResetState] = useState<boolean>(false);

  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new SolflareWalletAdapter(),

      // okx

      // Back pack
      // Megic Eden

      new BitgetWalletAdapter(),

      // gate adapter

      new CoinbaseWalletAdapter(),
      // new LedgerWalletAdapter(),
    ],
    [network]
  );
  const accountInfo = useRecoilValue(accountState);
  const walletInfoFromRecoil = useRecoilValueLoadable(walletStatusSelector);
  const [isOpenModalVerify, setIsOpenModalVerify] = useState<boolean>(false);
  const [isOpenModalConfirm, setIsOpenModalConfirm] = useState<boolean>(false);
  const [isOpenModalSelectWalletStep2, setIsOpenModalSelectWalletStep2] =
    useState<boolean>(false);
  const refreshWalletInfo = useRecoilRefresher_UNSTABLE(walletStatusSelector);
  const walletInfo = useMemo(() => {
    if (walletInfoFromRecoil?.state == "hasValue") {
      return walletInfoFromRecoil.contents;
    }
    return undefined;
  }, [walletInfoFromRecoil.state]);
  const [disconnectWallet, setDisconnectWallet] = useState(false);

  if (!walletInfoFromRecoil) return <Spin />;

  const handleClickStep1 = () => {
    setIsOpenModalVerify(true);
  };

  const handleClickStep2 = () => {
    setIsOpenModalSelectWalletStep2(true);
  };

  const handleClickStep3 = () => {
    setIsOpenModalConfirm(true);
  };

  const handleDisconnectWallet = () => {
    WalletService.disconnectWallet()
      .then((res) => {
        if (res.code === 0) {
          setDisconnectWallet(true);
          refreshWalletInfo();
          toastFn({
            type: "success",
            message: "Success",
            description: "Unlink wallet successfully!",
          });
        } else {
          toastFn({ message: "Error", description: res.msg });
        }
      })
      .catch((error) => {
        toastFn({
          message: "Error",
          description: error?.msg || "Something went error",
        });
      });
  };

  const renderSubtitleStep2 = () => {
    if (!walletInfo?.wallet_address) return;
    return (
      <div className="flex items-center gap-[2px] text-white cursor-pointer">
        <div
          className="flex items-center gap-[2px]"
          onClick={() => handleCopyWalletAddress(walletInfo?.wallet_address)}
        >
          {formatWalletAddress(walletInfo?.wallet_address)}
          <IconCopyWalletWhite />
        </div>
        {walletInfo?.status !== WALLET_STATUS.CONNECTED && (
          <IconDisconnectWallet onClick={handleDisconnectWallet} />
        )}
      </div>
    );
  };
  return (
    <>
      <WalletConnectionContainer>
        <WalletStep
          step={WALLET_STEP.STEP1}
          img={ImgWalletStep1}
          title="Verify Your Email"
          tooltip="Start by confirming your email to secure your Nodepay account."
          subTitle={accountInfo?.email}
          handleClick={handleClickStep1}
          status={walletInfo?.status}
        />
        <WalletStep
          step={WALLET_STEP.STEP2}
          img={ImgWalletStep2}
          title="Connect Your Wallet"
          tooltip="Link your crypto wallet to Nodepay for seamless token exchange."
          status={walletInfo?.status}
          subTitle={renderSubtitleStep2()}
          handleClick={handleClickStep2}
        />
        <WalletStep
          step={WALLET_STEP.STEP3}
          img={ImgWalletStep3}
          title="Confirm Wallet Address"
          tooltip="Check your email and confirm your wallet connection to finalize the setup."
          status={walletInfo?.status}
          handleClick={handleClickStep3}
        />
      </WalletConnectionContainer>

      <Modal
        title=""
        open={isOpenModalVerify}
        closeIcon={<img width={24} height={24} src={IconCloseModal} />}
        onCancel={() => setIsOpenModalVerify(false)}
        footer={null}
        width={572}
      >
        <ModalVerifyEmail email={accountInfo?.email} />
      </Modal>
      <Modal
        title=""
        open={isOpenModalConfirm}
        closeIcon={<img width={24} height={24} src={IconCloseModal} />}
        onCancel={() => setIsOpenModalConfirm(false)}
        footer={null}
        width={572}
      >
        <ModalConfirmEmail
          email={accountInfo?.email}
          wallet_address={walletInfo?.wallet_address}
        />
      </Modal>

      <Modal
        title=""
        open={isOpenModalSelectWalletStep2}
        closeIcon={<img width={24} height={24} src={IconCloseModal} />}
        onCancel={() => {
          setIsResetState(true);
          setIsOpenModalSelectWalletStep2(false);
          setDisconnectWallet(true);
        }}
        footer={null}
        width={572}
        maskClosable={false}
      >
        {typeDevice == TYPE_DEVICE.DESKTOP ? (
          <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} autoConnect={true}>
              <WalletModalProvider>
                <ModalSelectWallet
                  message={walletInfo?.message}
                  refreshWalletInfo={refreshWalletInfo}
                  setIsOpenModalSelectWalletStep2={
                    setIsOpenModalSelectWalletStep2
                  }
                  isResetState={isResetState}
                  setIsResetState={setIsResetState}
                  disconnectWallet={disconnectWallet}
                  setDisconnectWallet={setDisconnectWallet}
                />
              </WalletModalProvider>
            </WalletProvider>
          </ConnectionProvider>
        ) : (
          <ModalEnterWallet
            refreshWalletInfo={refreshWalletInfo}
            setIsOpenModalSelectWalletStep2={setIsOpenModalSelectWalletStep2}
          />
        )}
      </Modal>
    </>
  );
};

export default WalletConnection;
