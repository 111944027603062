import API from "../../constants/api";
import { getWithPath, postWithPath } from "../http";

const WalletService = {
  getWalletConnectionStatus: () => {
    return getWithPath(API.WALLET.GET.STATUS);
  },
  sendVerifyWallet: (data) => {
    return postWithPath(API.WALLET.POST.SEND_VERIFY_WALLET, {}, data);
  },
  verifyWallet: (data) => {
    return postWithPath(API.WALLET.POST.VERIFY_WALLET, {}, data);
  },
  disconnectWallet: () => {
    return postWithPath(API.WALLET.POST.DISCONNECT);
  },
  connectWallet: (data) => {
    return postWithPath(API.WALLET.POST.CONNECT, {}, data);
  },
  connectManualWallet: (data) => {
    return postWithPath(API.WALLET.POST.CONNECT_MANUAL, {}, data);
  },
};
export default WalletService;
