import { ArgsProps } from "antd/lib/notification";
import { notification } from "antd";
import { IconClose, IconCopySuccess, IconErrorMsg } from "../../../assets/icon";

type ToastType = "success" | "info" | "warning" | "error";
interface ToastProps extends ArgsProps {
  type?: ToastType;
  whiteBg?: boolean;
}

const MAP_NOTI_ICON = {
  error: <img src={IconErrorMsg} />,
  success: <img src={IconCopySuccess} />,
};

const toastFn = (props: ToastProps) => {
  const {
    type = "error",
    whiteBg,
    className,
    placement = "topRight",
    ...rest
  } = props;

  notification[type]({
    placement,
    className: `${whiteBg ? "is-white" : ""} ${className}`,
    closeIcon: <img src={IconClose} />,
    icon: MAP_NOTI_ICON[type],
    ...rest,
  });
};

export default toastFn;
