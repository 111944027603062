import { Layout } from "antd";
import { ReactComponent as IconLogo } from "../../../assets/icon/ic-logo-pc.svg";
import { ReactComponent as IconLogoMobile } from "../../../assets/icon/ic-logo-mobile.svg";

import classNames from "classnames";
import "./style.less";
import useCurrentWidth from "../../hook/useCurrentWidth";
import { TYPE_DEVICE, WIDTH_DEVICE } from "../../../constants";
import { useMemo } from "react";
import ResponsiveView from "../../components/ResponsiveView";
const { Header, Content, Footer, Sider } = Layout;

interface ILayoutAuthContainer {
  classNameHeader?: string;
  classNameContent?: string;
  children?: any;
}
const LayoutAuthContainer = ({
  children,
  classNameHeader,
  classNameContent = "",
}: ILayoutAuthContainer) => {
  const screenWidth = useCurrentWidth();
  const type = useMemo(() => {
    return screenWidth > WIDTH_DEVICE.TABLET
      ? TYPE_DEVICE.DESKTOP
      : screenWidth > WIDTH_DEVICE.MOBILE
      ? TYPE_DEVICE.TABLET
      : TYPE_DEVICE.MOBILE;
  }, [screenWidth]);

  return (
    <>
      <ResponsiveView type={type}>
        <Layout className="flex flex-col min-h-screen">
          <Header
            className={classNames(
              classNameHeader,
              {
                "bg-white lg:px-[80px] lg:py-[10px] p-4 lg:max-h-16 max-h-14":
                  !classNameHeader,
              },
              "flex justify-between items-center text-18px leading-7"
            )}
          >
            {type == TYPE_DEVICE.DESKTOP ? <IconLogo /> : <IconLogoMobile />}

            <span
              onClick={() => window.open("https://nodepay.ai/#blogs", "_blank")}
              className="text-18px text-neutral-110 cursor-pointer"
            >
              FAQ
            </span>
          </Header>
          <Layout>
            <Content
              className={classNames(
                "bg-grey-primary min-h-full grow",
                classNameContent
              )}
            >
              {children}
            </Content>
          </Layout>
        </Layout>
      </ResponsiveView>
    </>
  );
};

export default LayoutAuthContainer;
