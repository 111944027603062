export const buildParams = (data?: any) => {
  if (data) {
    const dataEdited = {
      ...data,
    };

    let queryData = {};
    try {
      queryData = Object.fromEntries(
        Object.entries(dataEdited).filter(([_, v]) => v != null && v !== "")
      );
    } catch (err) {
      console.error("Something error", err);
    }

    return Object.keys(queryData)
      .map((key) =>
        Array.isArray(queryData[key])
          ? `${key}=[${queryData[key]}]`
          : `${key}=${encodeURIComponent(queryData[key])}`
      )
      .join("&");
  }
  return "";
};
export const buildURLWithParam = (url: string, query?: any) => {
  return url + "?" + buildParams(query);
};

export function extend(obj1, obj2) {
  return { ...obj1, ...obj2 };
}
