import { Button, Form, Input, Modal } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useEffect, useState } from "react";
import ButtonDefault from "../../components/ButtonDefault";
import "./style.less";
import { ImgRewardBig } from "../../../assets/image";
import VoucherService from "../../../adapter/services/voucher";
import EarnService from "../../../adapter/services/earn";
import { setRecoil } from "recoil-nexus";
import { earnInfoState } from "../../../adapter/recoil/dashboard/state";

const ModalClaimVoucher = ({
  setIsModalClaimVoucher,
  setIsModalProfileOpen,
}) => {
  const [form] = useForm();
  const [disabledButton, setDisabledButton] = useState<boolean>(true);
  const values = Form.useWatch([], form);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [isShowModalCongratClaim, setIsShowModalCongratClaim] =
    useState<boolean>(false);
  const [dataClaim, setDataClaim] = useState<any>();
  useEffect(() => {
    let code = form.getFieldValue("code");
    if (code) {
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
  }, [form, values]);

  const handleClaimVoucher = (values) => {
    let voucher_code = values.code;
    setLoadingSubmit(true);
    VoucherService.claimVoucher({ voucher_code })
      .then((res) => {
        if (res?.code == 0 && res?.data) {
          setDataClaim(res.data);
          EarnService.getEarnInfo().then((result) => {
            if (result.code === 0 && result.data) {
              setRecoil(earnInfoState, result.data);
            }
          });
          setIsModalClaimVoucher(false);
          setIsShowModalCongratClaim(true);
          form.resetFields();
        } else {
          form.setFields([
            {
              name: "code",
              errors: [res.msg || "Invalid Code"],
            },
          ]);
        }
      })
      .catch((error) => {
        form.setFields([
          {
            name: "code",
            errors: [error.msg || "Invalid Code"],
          },
        ]);
      })
      .finally(() => {
        setLoadingSubmit(false);
      });
  };
  return (
    <>
      <div className="p-6">
        <Form
          form={form}
          name="basic"
          onFinish={handleClaimVoucher}
          autoComplete="off"
          layout="vertical"
          className="form-claim-voucher"
        >
          <Form.Item label="Enter Code" className="mb-3" name="code">
            <Input size="large" placeholder="Placeholder" />
          </Form.Item>
          <div className="flex items-center justify-end mt-12">
            <ButtonDefault
              className="mr-1 w-[94px]"
              onClick={() => {
                setIsModalClaimVoucher(false);
                setIsModalProfileOpen(true);
              }}
              text="Cancel"
            ></ButtonDefault>
            <Button
              type="text"
              size="large"
              disabled={disabledButton}
              htmlType="submit"
              className="w-[146px]"
              loading={loadingSubmit}
            >
              Claim Voucher
            </Button>
          </div>
        </Form>
      </div>
      <Modal
        className="modal-congrate-claim py-6 px-8 rounded-2xl "
        title=""
        open={isShowModalCongratClaim}
        closable={false}
        footer={null}
      >
        <div className="flex flex-col items-center justify-center">
          <img
            src={ImgRewardBig}
            className="w-[120px] h-[120px]"
            alt="logo-reward"
          />
          <div className="mt-6 font-bold text-18px text-neutral-110">
            Congratulation!
          </div>
          <div>
            <span className="text-18px font-bold text-neutral-110">
              You received
            </span>
            &nbsp;
            <span className="text-18px font-bold text-[#593FDE]">
              {dataClaim?.amount || "-"} Nodepay Points
            </span>
          </div>
          <div className="text-18px font-bold text-neutral-110">
            from Voucher Code
          </div>
          <Button
            className="mt-7"
            type="text"
            block
            size="large"
            onClick={() => {
              setIsShowModalCongratClaim(false);
              setIsModalProfileOpen(true);
            }}
          >
            Done
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default ModalClaimVoucher;
