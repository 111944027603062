import classNames from "classnames";
import { IconCheck, IconCheckActive } from "../../../../../assets/icon";

interface IHintPassword {
  value?: string;
  className?: string;
  classNameItem?: string;
}
const HintItem = ({ children, className, active = false }) => {
  return (
    <div
      className={classNames(className, "lg:w-6/12 w-full text-12px mb-1", {
        "hint-item-active text-green": active,
      })}
    >
      <img src={active ? IconCheckActive : IconCheck} />
      &nbsp;{children}
    </div>
  );
};
const HintPassword = ({
  value,
  className,
  classNameItem = "pl-3",
}: IHintPassword) => {
  value = value || "";
  return (
    <div className={classNames("flex flex-wrap", className)}>
      <HintItem
        className={classNames("lg:mb-2", classNameItem)}
        active={/[a-z]/.test(value)}
      >
        One lowercase character
      </HintItem>
      <HintItem
        className={classNames("lg:mb-2", classNameItem)}
        active={/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value)}
      >
        One special character
      </HintItem>
      <HintItem
        className={classNames("lg:mb-3", classNameItem)}
        active={/[A-Z]/.test(value)}
      >
        One uppercase character
      </HintItem>
      <HintItem
        className={classNames("mb-3", classNameItem)}
        active={value.length >= 8}
      >
        8 characters minimum
      </HintItem>
    </div>
  );
};

export default HintPassword;
