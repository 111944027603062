import React, { useEffect } from "react";
import {
  ImgBannerDownloadPC,
  ImgGooglePlay,
  ImgIOSStore,
  ImgLogo,
  ImgLogoWhiteDownload,
  ImgMobileDownload,
  ImgNpHiddenDownload,
} from "../../../assets/image";
import classNames from "classnames";
import { useRecoilValue } from "recoil";
import { typeViewState } from "../../../adapter/recoil/common/state";
import { LINK_MOBILE, TYPE_DEVICE } from "../../../constants";
import InfoDownload from "../components/InfoDownload";
import HeaderLogo from "../components/HeaderLogo";
import MobileDownload from "./mobile";

export function redirectToAppStoreOrPlayStore() {
  const userAgent = navigator.userAgent?.toLowerCase();
  if (userAgent.match(/ipad|iphone|ipod/i)) {
    window.location.href = LINK_MOBILE.IOS;
  } else if (userAgent.match(/android/i)) {
    window.location.href = LINK_MOBILE.ANDROID;
  } else {
    //
  }
}
const DownloadView = () => {
  const typeDevice: any = useRecoilValue(typeViewState);
  if (typeDevice !== TYPE_DEVICE.DESKTOP) return <MobileDownload />;

  return (
    <div
      className="relative w-screen h-screen flex lg:flex-row flex-col"
      style={{
        background: `radial-gradient(900.47% 249.87% at 112.95% 147.67%, #6536DA 0%, #05000E 87.65%)`,
      }}
    >
      <div className="lg:w-4/12 w-8/12">
        <HeaderLogo />
        <div className="absolute top-1/2 -translate-y-1/2 2xl:left-[150px] left-[50px] xl:w-fit w-[500px]">
          <InfoDownload />
        </div>
      </div>
      <div className="absolute right-0 bottom-0  md:w-[1000px] sm:max-w-screen-md xxl:max-w-[1000px] flex justify-center items-end ">
        <div
          className="absolute right-0 bottom-0 w-full aspect-[931/450] bg-cover bg-no-repeat bg-center-bottom"
          style={{ backgroundImage: `url(${ImgBannerDownloadPC})` }}
        ></div>
        <img
          className="z-50 object-cover 2xl:h-[921px] h-[700px] xl:w-[5/12] w-[4/12]"
          src={ImgMobileDownload}
        />
      </div>
    </div>
  );
};

export default DownloadView;
