import { Button, Form, Input } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useEffect, useState } from "react";
import Turnstile, { useTurnstile } from "react-turnstile";
import { ReCAPTCHA_PUBLIC_KEY } from "../../../../../constants";
import WalletService from "../../../../../adapter/services/wallet";
import toastFn from "../../../../../shared/components/ToastNoti";

const ModalEnterWallet = ({
  refreshWalletInfo,
  setIsOpenModalSelectWalletStep2,
}) => {
  const turnstile = useTurnstile();
  const [form] = useForm();
  const values = Form.useWatch([], form);
  const [disabledButton, setDisabledButton] = useState<boolean>(true);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);

  useEffect(() => {
    let wallet_address = form.getFieldValue("wallet_address");
    if (wallet_address) {
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
  }, [form, values]);

  const handleConnectManual = (values) => {
    setLoadingSubmit(true);
    let dataSubmit = {
      wallet_address: values?.wallet_address,
      recaptcha_token: turnstile.getResponse(),
    };

    WalletService.connectManualWallet(dataSubmit)
      .then((res) => {
        if (res.code === 0) {
          toastFn({
            type: "success",
            message: (
              <div className="flex flex-col">
                <div className="text-14px text-neutral-110">
                  Wallet connection successful
                </div>
              </div>
            ),
          });
          setIsOpenModalSelectWalletStep2(false);
        } else {
          toastFn({ message: "Error", description: res.msg });
        }
      })
      .catch((error) => {
        toastFn({
          message: "Error",
          description: error.msg || "Something went error",
        });
      })
      .finally(() => {
        setLoadingSubmit(false);
        refreshWalletInfo();
        turnstile.reset();
      });
  };

  return (
    <div>
      <div className="mt-6 text-neutral-110 font-bold text-18px">
        Enter Solana Wallet address
      </div>
      <Form
        form={form}
        name="basic"
        onFinish={handleConnectManual}
        autoComplete="off"
        layout="vertical"
      >
        <Form.Item className="mt-6 mb-3" name="wallet_address">
          <Input size="large" placeholder="Enter your wallet address" />
        </Form.Item>
        <Form.Item className="mb-3">
          <Turnstile className="w-full" sitekey={ReCAPTCHA_PUBLIC_KEY} />
        </Form.Item>
        <div className="flex items-center justify-end">
          <Button
            type="text"
            size="large"
            disabled={disabledButton}
            htmlType="submit"
            className="w-full"
            loading={loadingSubmit}
          >
            Confirm
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default ModalEnterWallet;
