import React from "react";
import "./style.css";
import classNames from "classnames";

interface IButtonDefault {
  className?: string;
  text: string;
  onClick: any;
  size?: string;
  icon?: any;
  suffixIcon?: any;
  disabled?: boolean;
}
const ButtonDefault = ({
  className,
  text,
  onClick,
  size,
  icon = <></>,
  suffixIcon = <></>,
  disabled = false,
}: IButtonDefault) => {
  return (
    <div
      className={classNames("btn-default", className, {
        "btn-default-large": size === "large",

        "opacity-50": disabled,
        "btn-default-enable cursor-pointer": !disabled,
      })}
      onClick={disabled ? undefined : onClick}
    >
      <div className="btn-default-content">
        {icon}
        <span>{text}</span>
        {suffixIcon}
      </div>
    </div>
  );
};

export default ButtonDefault;
