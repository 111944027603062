import {
  IconDashboardActive,
  IconDashboardInactive,
  IconDashboardMobileActive,
  IconMenuMedal,
  IconMenuMedalInactive,
  IconEarningActive,
  IconEarningInactive,
  IconMenuMoreActive,
  IconMenuMoreInactive,
  IconReferral,
  IconReferralActive,
  IconReferralMobileActive,
  IconRewardActive,
  IconRewardInactive,
  IconTransHistoryActive,
  IconTransHistoryInactive,
} from "../../assets/icon";
import ROUTE from "../../constants/route";

export enum MENU_TYPE {
  DASHBOARD = "1",
  REFERRAL = "2",
  REWARD = "3",
  MEDAL = "4",
  TRANSACTION_HISTORY = "5",
  EARNING = "6",
  MORE = "10",
}
export const MAP_MENU = {
  [MENU_TYPE.DASHBOARD]: {
    key: MENU_TYPE.DASHBOARD,
    active: IconDashboardActive,
    inactive: IconDashboardInactive,
    activeMobile: IconDashboardMobileActive,
    path: ROUTE.DASHBOARD,
    name: "Dashboard",
  },
  [MENU_TYPE.REFERRAL]: {
    key: MENU_TYPE.REFERRAL,
    active: IconReferralActive,
    activeMobile: IconReferralMobileActive,
    inactive: IconReferral,
    path: ROUTE.REFERRAL,
    name: "Referral Program",
  },
  [MENU_TYPE.REWARD]: {
    key: MENU_TYPE.REWARD,
    active: IconRewardActive,
    inactive: IconRewardInactive,
    path: ROUTE.MISSIONS_REWARD,
    name: "Mission & Earn",
  },
  [MENU_TYPE.MEDAL]: {
    key: MENU_TYPE.REWARD,
    active: IconMenuMedal,
    inactive: IconMenuMedalInactive,
    path: ROUTE.MEDAL,
    name: "Medal & Boost",
  },
  [MENU_TYPE.TRANSACTION_HISTORY]: {
    key: MENU_TYPE.TRANSACTION_HISTORY,
    active: IconTransHistoryActive,
    inactive: IconTransHistoryInactive,
    path: ROUTE.TRANSACTION_HISTORY,
    name: "Transaction History",
  },
  [MENU_TYPE.EARNING]: {
    key: MENU_TYPE.EARNING,
    active: IconEarningActive,
    inactive: IconEarningInactive,
    path: ROUTE.EARNING,
    name: "Earnings",
  },
  [MENU_TYPE.MORE]: {
    key: MENU_TYPE.MORE,
    active: IconMenuMoreActive,
    inactive: IconMenuMoreInactive,
    path: undefined,
    name: "More",
  },
};
