import React from "react";
import {
  ImgLogoWhiteDownload,
  ImgNpHiddenDownload,
} from "../../../assets/image";

const HeaderLogo = () => {
  return (
    <div
      className="relative w-full h-full bg-no-repeat"
      style={{
        backgroundImage: `url(${ImgNpHiddenDownload})`,
        backgroundPosition: "top left",
      }}
    >
      <img
        className="absolute top-[70px] left-[150px]  h-7 w-[189px]"
        src={ImgLogoWhiteDownload}
        alt="logo"
      />
    </div>
  );
};

export default HeaderLogo;
