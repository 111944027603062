import API from "../../constants/api";
import { getWithPath, postWithPath } from "../http";

const AuthService = {
  register: (data) => {
    return postWithPath(API.AUTH.POST.REGISTER, {}, data);
  },
  login: (data) => {
    return postWithPath(API.AUTH.POST.LOGIN, {}, data);
  },
  forgotPassword: (data) => {
    return postWithPath(API.AUTH.POST.FORGOT_PASSWORD, {}, data);
  },
  validateReferralCode: (data) => {
    return postWithPath(API.AUTH.POST.VALIDATE_REFERRAL_CODE, {}, data);
  },
  resetPassword: (data) => {
    return postWithPath(API.AUTH.POST.RESET_PASSWORD, {}, data);
  },
  changePassword: (data) => {
    return postWithPath(API.AUTH.POST.CHANGE_PASSWORD, {}, data);
  },
  postSession: (data) => {
    return postWithPath(API.AUTH.POST.SESSION, {}, data);
  },
  changeEmail: (data) => {
    return postWithPath(API.AUTH.POST.CHANGE_EMAIL, {}, data);
  },
  sendVerifyEmail: () => {
    return postWithPath(API.AUTH.POST.SEND_VERIFY_EMAIL);
  },
  connectDiscord: () => {
    return getWithPath(API.AUTH.GET.CONNECT_DISCORD);
  },
  disconnectDiscord: () => {
    return getWithPath(API.AUTH.GET.DISCONNECT_DISCORD);
  },
  connectX: () => {
    return getWithPath(API.AUTH.GET.CONNECT_X);
  },
  disconnectX: () => {
    return getWithPath(API.AUTH.GET.DISCONNECT_X);
  },
  joinDiscord: (data) => {
    return postWithPath(API.AUTH.POST.JOIN_DISCORD, {}, data);
  },
  followX: (data) => {
    return postWithPath(API.AUTH.POST.FOLLOW_X, {}, data);
  },
  verifyEmail: (data) => {
    return postWithPath(API.AUTH.POST.VERIFY_EMAIL, {}, data);
  },
  getWalletCodeChallenge: () => {
    return getWithPath(API.AUTH_WALLET.GET.LOGIN_CODE_CHALLENGE);
  },
  loginByWallet: (data) => {
    return postWithPath(API.AUTH_WALLET.POST.LOGIN, {}, data);
  },
};
export default AuthService;
