import { Button, Form, Input } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useEffect, useState } from "react";
import ButtonDefault from "../../components/ButtonDefault";
import Turnstile, { useTurnstile } from "react-turnstile";
import { PATTERN, ReCAPTCHA_PUBLIC_KEY } from "../../../constants";
import AuthService from "../../../adapter/services/auth";
import toastFn from "../../components/ToastNoti";
import { setRecoil } from "recoil-nexus";
import { accountState } from "../../../adapter/recoil/common/state";

const ModalEditEmail = ({
  currentEmail,
  setIsModalProfileOpen,
  setIsModalEditEmail,
}) => {
  const [form] = useForm();
  const values = Form.useWatch([], form);
  const turnstile = useTurnstile();

  const [disabledButton, setDisabledButton] = useState<boolean>(true);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);

  useEffect(() => {
    let email = form.getFieldValue("email");
    if (email) {
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
  }, [form, values]);

  const refetchSession = () => {
    AuthService.postSession({})
      .then((res) => {
        if (res.code === 0) {
          setRecoil(accountState, res.data);
        } else {
          return;
        }
      })
      .catch((error) => {
        console.log(error);

        return;
      });
  };
  const handleEditEmail = (values) => {
    setLoadingSubmit(true);
    let dataSubmit = {
      email: values?.email,
      recaptcha_token: turnstile.getResponse(),
    };

    AuthService.changeEmail(dataSubmit)
      .then((res) => {
        if (res.code == 0) {
          toastFn({
            type: "success",
            message: "Send change email successfully!",
          });
          setIsModalEditEmail(false);
          setIsModalProfileOpen(true);
          refetchSession();
        } else {
          toastFn({
            message: "Error",
            description: res.msg || "Something went error",
          });
          turnstile.reset();
        }
      })
      .catch((error) => {
        toastFn({
          message: "Error",
          description: error.msg || "Something went error",
        });
        turnstile.reset();
      })
      .finally(() => {
        setLoadingSubmit(false);
      });
  };
  return (
    <Form
      form={form}
      name="basic"
      onFinish={handleEditEmail}
      autoComplete="off"
      layout="vertical"
      className="p-6"
    >
      <Form.Item
        name="email"
        initialValue={currentEmail}
        rules={[
          {
            required: true,
            validateTrigger: "onSubmit",
            message: "Input email address",
          },
          {
            validator: (_, value) => {
              if (PATTERN.EMAIL.test(value)) {
                setDisabledButton(false);
                return Promise.resolve();
              } else {
                return Promise.reject("Invalid email address");
              }
            },
          },
        ]}
      >
        <Input size="large" placeholder="Enter your new email" />
      </Form.Item>
      <Form.Item className="lg:mt-6 mt-5">
        <Turnstile className="w-full" sitekey={ReCAPTCHA_PUBLIC_KEY} />
      </Form.Item>
      <div className="flex items-center justify-end mt-12">
        <ButtonDefault
          className="mr-1 w-[94px]"
          onClick={() => {
            setIsModalEditEmail(false);
            setIsModalProfileOpen(true);
          }}
          text="Cancel"
        ></ButtonDefault>
        <Button
          type="text"
          size="large"
          disabled={disabledButton}
          htmlType="submit"
          className="w-[146px]"
          loading={loadingSubmit}
        >
          Confirm
        </Button>
      </div>
    </Form>
  );
};

export default ModalEditEmail;
