import API from "../../constants/api";
import { getWithPath, postWithPath } from "../http";

const MedalService = {
  getAll: () => {
    return getWithPath(API.MEDAL.GET.ALL);
  },
  claimMedal: (data) => {
    return postWithPath(API.MEDAL.POST.CLAIM_MEDAL, {}, data);
  },
};
export default MedalService;
