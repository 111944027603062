import dayjs from "dayjs";
import { atom, selector } from "recoil";
import UserService from "../../services/user";
import { SOCIAL_PROVIDER } from "../../../constants/enum";

export const tokenState = atom({
  key: "tokenState",
  default: undefined,
});

export const accountState = atom({
  key: "accountState",
  default: undefined,
});

export const networkEarningRateSelector = selector({
  key: "networkEarningRateSelector",
  get: ({ get }) => {
    let accountInfo = get(accountState);
    return accountInfo?.network_earning_rate;
  },
});

export const typeViewState = atom({
  key: "typeViewState",
});

export const isInTimeMaintain = selector({
  key: "isInTimeMaintainState",
  get: () => {
    let endDate = dayjs(new Date(1720795200000)).add(7, "day").toDate(); //21:40 12/7
    let endTime = new Date(endDate).getTime();
    let currentTime = new Date().getTime();
    if (endTime < currentTime) {
      return false;
    } else return true;
  },
});

export const infoSocialState = selector({
  key: "infoSocialSelector",
  get: async () => {
    let res = await UserService.getUserSocial();
    if (res.code === 0) {
      let list = res.data;
      let info: any = {
        [SOCIAL_PROVIDER.DISCORD]: undefined,
        [SOCIAL_PROVIDER.X]: undefined,
        [SOCIAL_PROVIDER.EMAIL]: undefined,
      };
      for (let ele of list) {
        if (ele.social_provider === SOCIAL_PROVIDER.DISCORD) {
          info[SOCIAL_PROVIDER.DISCORD] = ele;
        }
        if (ele.social_provider == SOCIAL_PROVIDER.X) {
          info[SOCIAL_PROVIDER.X] = ele;
        }
        if (ele.social_provider == SOCIAL_PROVIDER.EMAIL) {
          info[SOCIAL_PROVIDER.EMAIL] = ele;
        }
      }
      return info;
    }
    return;
  },
});
