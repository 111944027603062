import { selector } from "recoil";
import WalletService from "../../services/wallet";
import { WALLET_STATUS } from "../../../constants/enum";

export const walletStatusSelector = selector({
  key: "walletStatusSelectorInfo",
  get: async () => {
    let res = await WalletService.getWalletConnectionStatus();
    if (res.code === 0) {
      return res.data;
    }
    return undefined;
  },
});
