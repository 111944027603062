import API from "../../constants/api";
import { getWithPath, postWithPath } from "../http";

const ScrapegraphService = {
  getSample: () => {
    return getWithPath(API.SCRAPEGRAPH.GET.SAMPLE);
  },
  crawlData: (data) => {
    return postWithPath(API.SCRAPEGRAPH.POST.CRAWL, {}, data);
  },
};
export default ScrapegraphService;
