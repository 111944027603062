const ROUTE = {
  REGISTER: "/register",
  REGISTER_RESULT: "/register-successfully",
  LOGIN: "/login",
  FORGOT_PASSWORD: "/forgot-password",
  CREATE_NEW_PASSWORD: "/change-password",
  DASHBOARD: "/dashboard",
  REFERRAL: "/referral",
  TRANSACTION_HISTORY: "/transaction-history",
  EARNING: "/earnings",
  MISSIONS_REWARD: "/missions",
  VERIFY_EMAIL: "/verify-email",
  SCRAPEGRAPH: "/scrapegraph",
  OAUTH_PERMISSION: "/oauth/permission",
  MEDAL: "/medal",
  BOOST_CAMPAIGN: "/boost-campaign",
  VERIFY_WALLET: "/verify-wallet",
  DOWNLOAD_APP: "/download",
};
export default ROUTE;
