import { useState, useEffect, useRef } from "react";
import queryString from "query-string";

const useQuery = () => {
  const initRef = useRef<boolean>(false);

  const [state, setState] = useState(queryString.parse(window.location.search));

  useEffect(() => {
    if (initRef.current) {
      setState(queryString.parse(window.location.search));
    } else {
      initRef.current = true;
    }
  }, [window.location.search]);

  return state;
};

export default useQuery;
