import React from "react";
import { ImgFollowCTAX, ImgLogoNodepayX } from "../../../assets/image";
import { IconTickBlueX } from "../../../assets/icon";
import classNames from "classnames";
import { TYPE_DEVICE } from "../../../constants";

const ModalPopupTelegram = ({ typeDevide }) => {
  return (
    <div
      className={classNames(
        "test rounded-xl py-[66px] flex flex-col items-center",
        {
          "px-[33px] max-w-[460px] ": typeDevide != TYPE_DEVICE.MOBILE,
          "px-4 max-w-[360px]": typeDevide == TYPE_DEVICE.MOBILE,
        }
      )}
    >
      <div className="md:w-[359px] w-[250px] flex flex-col items-center text-center justify-center mb-[52px]">
        <div className="text-gradient text-24px font-bold mb-2">
          Limited time offer!
        </div>
        <span className="text-16px text-white">
          Free points on our Telegram! Join now to be eligible and find them
          before they’re gone!
        </span>
      </div>
      <div
        className={classNames("bg-neutral-110 rounded-lg py-4", {
          "px-6 max-w-[380px] w-[380px] ": typeDevide != TYPE_DEVICE.MOBILE,
          "max-w-[324px] w-[324px] px-2": typeDevide == TYPE_DEVICE.MOBILE,
        })}
      >
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <img
              className="lg:w-[56px] lg:h-[56px] object-cover w-[40px] h-[40px]"
              src={ImgLogoNodepayX}
            />
            <div className="flex flex-col ml-[10px]">
              <div className="flex items-center">
                <span className="text-16px text-white font-bold">Nodepay</span>
                &nbsp;
                <img src={IconTickBlueX} width={20} height={20} alt="" />
              </div>
              <span className="text-neutral-60">2,000 members</span>
            </div>
          </div>
          <div
            className="w-[140px] h-[65px] cursor-pointer relative"
            onClick={() => {
              window.open("https://t.me/nodepaycommunitychat", "_blank");
            }}
          >
            <img
              className="w-[140px] h-[65px] object-fit"
              src={ImgFollowCTAX}
              alt=""
            />
            <span className="absolute w-[100px] bg-white font-bold text-neutral-110 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              Join Telegram
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalPopupTelegram;
