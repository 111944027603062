const AUTH = {
  GET: {
    CONNECT_DISCORD: "/auth/discord/connect",
    DISCONNECT_DISCORD: "/auth/discord/disconnect",
    CONNECT_X: "/auth/x/connect",
    DISCONNECT_X: "/auth/x/disconnect",
  },
  POST: {
    REGISTER: "/auth/register",
    LOGIN: "/auth/login",
    FORGOT_PASSWORD: "/auth/forgot-password",
    VALIDATE_REFERRAL_CODE: "/auth/validate-referral-code",
    RESET_PASSWORD: "/auth/reset-password",
    CHANGE_PASSWORD: "/auth/change-password",
    SESSION: "/auth/session",
    CHANGE_EMAIL: "/auth/send-change-email",
    //Flow mission
    SEND_VERIFY_EMAIL: "/auth/send-verify-email",
    JOIN_DISCORD: "/auth/discord/join",
    FOLLOW_X: "/auth/x/verify-follow",
    VERIFY_EMAIL: "/auth/verify-email",
  },
};
const AUTH_WALLET = {
  GET: {
    LOGIN_CODE_CHALLENGE: "/auth/wallet/code",
  },
  POST: {
    LOGIN: "/auth/wallet/login",
  },
};
const EARN = {
  GET: {
    INFO: "/earn/info",
    DAILY_STATISTIC: "/earn/daily-statistic",
  },
};
const REWARD = {
  GET: {
    REFERRAL_INFO: "/reward/referral-info",
    CLAIMABLE_REWARD: "/reward/claimable-referral-reward",
    CAMPAIGN: "/reward/campaign",
  },
  POST: {
    CLAIM_REWARD: "/reward/claim-referral-reward",
  },
};
const VOUCHER = {
  POST: {
    CLAIM_VOUCHER: "/voucher/claim-voucher",
  },
};
const TRANSACTION = {
  GET: {
    TRANSACTION_HISTORY: "/transaction/history",
  },
};

const NETWORK = {
  GET: {
    DEVICE_NETWORKS: "/network/device-networks",
  },
  POST: {
    UPDATE_NAME: "/network/update-network-name",
  },
};

const MISSION = {
  GET: {
    LIST: "/mission",
    USER_REWARD: "/mission/user-reward",
    SURVEY_ENUM: "/mission/survey/qna-enum",
  },
  POST: {
    COMPLETE_MISSION: "/mission/complete-mission",
    QNA_CHALLENGE: "/mission/survey/qna-challenge",
    QNA_CHALLENGE_2: "/mission/survey/qna-challenge-2",
    QNA_CHALLENGE_3: "/mission/survey/qna-challenge-3",
  },
};

const USER = {
  GET: {
    SOCIAL: "/user/social",
    MEDAL: "/user/medal",
  },
};
const SCRAPEGRAPH = {
  GET: {
    SAMPLE: "/scrape/prompt-sample",
  },
  POST: {
    CRAWL: "/scrape/crawl",
  },
};

const MEDAL = {
  GET: {
    ALL: "/medal/all",
  },
  POST: {
    CLAIM_MEDAL: "/medal/claim",
  },
};
const BANNER = {
  GET: {
    LIST: "/banner",
  },
};

const REFERRAL = {
  GET: {
    REFERRAL_PROGRAM_STATUS: "/referral/referral-program-status",
    REFERRAL_CAMPAIGN: "/referral/campaign",
    CLAIMABLE_REFERRAL_REWARD: "/referral/claimable-referral-reward",
    USER_REFERRAL_INFO: "/referral/user-referral-program-info",
  },
  POST: {
    CLAIM_REWARD: "/referral/claim-referral-reward",
  },
};

const SEASON = {
  GET: {
    USER_SEASON: "/season/user",
  },
};

const BOOST_CAMPAIGN = {
  GET: {
    LIST: "/boost-campaign",
    USER_BENEFIT: "/boost-campaign/user-benefit",
  },
};

const OAUTH = {
  GET: {
    APP_INFO: "/oauth2/app-info",
  },
  POST: {
    GRANT_PERMISSION: "/oauth/grant",
  },
};

const WALLET = {
  GET: {
    STATUS: "/wallet/status",
  },
  POST: {
    VERIFY_WALLET: "/wallet/verify-wallet",
    SEND_VERIFY_WALLET: "/wallet/send-verify-wallet",
    DISCONNECT: "/wallet/disconnect",
    CONNECT: "/wallet/connect",
    CONNECT_MANUAL: "/wallet/connect-manual",
  },
};
const API = {
  AUTH,
  EARN,
  REWARD,
  VOUCHER,
  TRANSACTION,
  NETWORK,
  MISSION,
  SCRAPEGRAPH,
  BANNER,
  OAUTH,
  USER,
  REFERRAL,
  SEASON,
  MEDAL,
  BOOST_CAMPAIGN,
  WALLET,
  AUTH_WALLET,
};
export default API;
