import React from "react";
import BoxContainer from "../BoxContainer";
import { ImgResultError, ImgSuccess } from "../../../assets/image";
import { Button } from "antd";

interface IResultView {
  btnText: string;
  handleClickBtn?: any;
  elementContent?: any;
  success?: boolean;
}
const ResultView = ({
  btnText,
  handleClickBtn,
  elementContent,
  success = true,
}: IResultView) => {
  return (
    <BoxContainer className="py-6 px-8 lg:py-6 lg:px-8" title="">
      <div className="lg:min-w-[416px] flex flex-col items-center justify-center text-center">
        <div className="w-20 h-20">
          <img
            className="w-20 h-20 object-fit"
            src={success ? ImgSuccess : ImgResultError}
            alt=""
          />
        </div>
        {elementContent}
        <Button type="text" block size="large" onClick={handleClickBtn}>
          {btnText}
        </Button>
      </div>
    </BoxContainer>
  );
};

export default ResultView;
