import React from "react";
import {
  ImgBannerDownloadPC,
  ImgGooglePlay,
  ImgIOSStore,
  ImgLogo,
  ImgLogoWhiteDownload,
  ImgMobileDownload,
  ImgNpHiddenDownload,
} from "../../../assets/image";
import classNames from "classnames";
import { useRecoilValue } from "recoil";
import { typeViewState } from "../../../adapter/recoil/common/state";
import { LINK_MOBILE, TYPE_DEVICE } from "../../../constants";
import QRCode from "./QRCode";

const InfoDownload = ({ isResponsive = false }) => {
  return (
    <div
      className={classNames("flex flex-col flex-wrap w-full", {
        "items-center": isResponsive,
      })}
    >
      <span className="lg:text-40px text-24px text-white font-bold">
        Dowload Mobile Application
      </span>
      <span className="mt-2 lg:text-18px text-16px text-white">
        Earn A Share In The Growth of AI
      </span>

      <div className="lg:mt-9 mt-8">
        <QRCode />
      </div>
      <div className="flex items-center mt-4 gap-2">
        <img
          className="cursor-pointer"
          height={44}
          src={ImgIOSStore}
          onClick={() => (window.location.href = LINK_MOBILE.IOS)}
        />
        <img
          className="cursor-pointer"
          height={44}
          src={ImgGooglePlay}
          onClick={() => (window.location.href = LINK_MOBILE.ANDROID)}
        />
      </div>
    </div>
  );
};

export default InfoDownload;
