import { Button, Switch } from "antd";

const ModalVerifyStep2 = ({ handleSignMessage, setLedger }) => {
  return (
    <div className="flex flex-col gap-8 w-full mt-6">
      <div className="text-neutral-110 font-bold text-24px">Verify Wallet</div>
      <div className="px-4 py-3 rounded-lg bg-[#F2F4F8] text-neutral-110 flex flex-col">
        <span className="text-18px font-bold text-neutral-110 mb-4">
          Sign Message to Verify Wallet Address
        </span>
        <div className="text-16px mb-3">
          By signing this message, you are binding this wallet to all activities
          associated to your Nodepay account and agree to our{" "}
          <a
            className="text-purple underline"
            href="https://nodepay.ai/terms-conditions/"
            target="_blank"
            rel="noreferrer"
          >
            Terms and Conditions
          </a>{" "}
          and{" "}
          <a
            className="text-purple underline"
            href="https://nodepay.ai/privacy-policy/"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy.
          </a>
        </div>
        <div className="text-16px">
          After wallet verification, there is no way to change the wallet
          address connected to your dashboard. It is your responsibility to
          ensure that your chosen wallet is secure.
        </div>
        <div className="flex items-center my-2">
          <Switch
            defaultChecked={false}
            onChange={(value) => setLedger(value)}
          />
          <span className="text-16px ml-2">Are you using a Ledger?</span>
        </div>
        <div className="text-16px">
          You maybe be required to pay gas to sign the message using a Ledger.
        </div>
      </div>

      <Button type="text" block size="large" onClick={handleSignMessage}>
        Verify
      </Button>
    </div>
  );
};

export default ModalVerifyStep2;
