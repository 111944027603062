import { useNavigate } from "react-router-dom";
import { LINK_DOWNLOAD_EXT } from "../../../constants";
import { ACCOUNT_TYPE } from "../../../constants/enum";
import BannerContainer from "../BannerContainer";
import ROUTE from "../../../constants/route";

const Banner = ({ accountInfo, typeDevice }) => {
  const navigate = useNavigate();
  return (
    <>
      <BannerContainer
        hidden={accountInfo?.state !== ACCOUNT_TYPE.INACTIVE}
        typeDevice={typeDevice}
      >
        <span className="text-white">
          Activate to receive Nodepay bonus points. To activate,&nbsp;
        </span>
        <a
          className="font-bold text-white cursor-pointer"
          onClick={() => window.open(LINK_DOWNLOAD_EXT, "_blank")}
        >
          Click Here
        </a>
      </BannerContainer>
      {/* <BannerContainer
        hidden={accountInfo?.state === ACCOUNT_TYPE.INACTIVE}
        typeDevice={typeDevice}
      >
        <span className="text-white">
          Connect your Solana wallet by Dec 5th, 1 AM UTC to qualify for the
          Nodepay airdrop!&nbsp;
        </span>
        <a
          className="font-bold text-white cursor-pointer"
          onClick={() => navigate(ROUTE.EARNING)}
        >
          CONNECT NOW
        </a>
      </BannerContainer> */}
    </>
  );
};

export default Banner;
