import React, { useEffect } from "react";
import HeaderLogo from "../components/HeaderLogo";
import InfoDownload from "../components/InfoDownload";
import {
  ImgBannerDownloadMobile,
  ImgBannerDownloadPC,
  ImgDownloadMobileResponsive,
  ImgLogoWhiteDownload,
  ImgNpHiddenDownload,
  ImgNpHiddenDownloadMobile,
} from "../../../assets/image";
import { TYPE_DEVICE } from "../../../constants";
import { useRecoilValue } from "recoil";
import { typeViewState } from "../../../adapter/recoil/common/state";
import { redirectToAppStoreOrPlayStore } from ".";
import useCurrentWidth from "../../../shared/hook/useCurrentWidth";

const MobileDownload = () => {
  const typeDevice: any = useRecoilValue(typeViewState);
  const screenWidth = useCurrentWidth();
  useEffect(() => {
    redirectToAppStoreOrPlayStore();
  }, []);
  return (
    <div
      className="w-sreen min-h-screen h-full flex-flex-col items-center"
      style={{
        background: `radial-gradient(900.47% 249.87% at 112.95% 147.67%, #6536DA 0%, #05000E 87.65%)`,
      }}
    >
      <div className="mb-10 relative w-full h-[560px]">
        <div
          className="w-full h-[300px] absolute left-[-56px] top-[-40px] bg-no-repeat"
          style={{
            backgroundImage: `url(${ImgNpHiddenDownload})`,
            backgroundPosition: "top left",
          }}
        ></div>
        <img
          className="absolute top-[70px] left-[130px] h-7 w-[189px]"
          src={ImgLogoWhiteDownload}
          alt="logo"
        />{" "}
        <div
          className="absolute bottom-0 left-1/2 -translate-x-1/2 w-[600px] z-50"
          style={{
            maxWidth: "100vw",
          }}
        >
          <InfoDownload isResponsive={true} />
        </div>
      </div>
      <div className="h-[650px] flex justify-center">
        <img
          className="absolute w-[300px] z-50 object-cover"
          src={ImgDownloadMobileResponsive}
        />
      </div>
      {screenWidth < 500 ? (
        <div
          className="z-10 fixed right-0 bottom-0 w-screen aspect-[800/1600] bg-cover bg-no-repeat lg:bg-right-bottom bg-center-bottom"
          style={{ backgroundImage: `url(${ImgBannerDownloadMobile})` }}
        ></div>
      ) : (
        <div
          className="z-10 fixed right-0 bottom-0 aspect-[931/450] w-screen bg-cover bg-no-repeat bg-center"
          style={{ backgroundImage: `url(${ImgBannerDownloadPC})` }}
        ></div>
      )}
    </div>
  );
};

export default MobileDownload;
