import { Button, Form, Input } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useEffect, useState } from "react";
import { IconLock } from "../../../assets/icon";
import HintPassword from "../../../module/account/create-new-password/views/components/HintPassword";
import AuthService from "../../../adapter/services/auth";
import toastFn from "../../components/ToastNoti";
import ButtonDefault from "../../components/ButtonDefault";

const ModalChangePassword = ({
  setIsModalChangePassOpen,
  setIsModalProfileOpen,
}) => {
  const [form] = useForm();
  const [valuePassword, setValuePassword] = useState<string>("");
  const [disabledButton, setDisabledButton] = useState<boolean>(true);
  const values = Form.useWatch([], form);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setDisabledButton(false))
      .catch(() => setDisabledButton(true));
  }, [form, values]);

  const handleChangePassword = (values) => {
    setLoadingSubmit(true);
    AuthService.changePassword({
      old_password: values?.old_password,
      password: values?.password,
    })
      .then((res) => {
        if (res.code == 0) {
          toastFn({
            type: "success",
            message: "Change password successfully!",
          });
          setIsModalChangePassOpen(false);
          setIsModalProfileOpen(true);
        } else {
          toastFn({
            message: "Error",
            description: res.msg || "Something went error",
          });
        }
      })
      .catch((error) => {
        toastFn({
          message: "Error",
          description: error.msg || "Something went error",
        });
      })
      .finally(() => {
        setLoadingSubmit(false);
      });
  };
  return (
    <div className="p-6">
      <Form
        form={form}
        name="basic"
        onFinish={handleChangePassword}
        //onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          className="mb-3"
          name="old_password"
          validateTrigger="onSubmit"
          rules={[
            {
              required: true,
              message: "Input current password",
            },
          ]}
        >
          <Input.Password
            size="large"
            placeholder="Currrent password"
            prefix={<img src={IconLock} />}
          />
        </Form.Item>
        <Form.Item className="mb-3" name="password">
          <Input.Password
            size="large"
            placeholder="New password"
            prefix={<img src={IconLock} />}
            onChange={(e) => setValuePassword(e.target.value)}
          />
        </Form.Item>
        <HintPassword value={valuePassword} classNameItem="pl-0" />
        <Form.Item
          name="confirm_password"
          rules={[
            {
              required: true,
              validateTrigger: "onSubmit",
              message: "Input confirm password",
            },
            {
              validator: (_, value) => {
                if (value !== valuePassword) {
                  return Promise.reject("Your password does not match");
                }
                setDisabledButton(false);
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input.Password
            size="large"
            placeholder="Confirm password"
            prefix={<img src={IconLock} />}
          />
        </Form.Item>
        <div className="flex items-center justify-end mt-8">
          <ButtonDefault
            className="mr-1 w-[94px]"
            onClick={() => {
              setIsModalChangePassOpen(false);
              setIsModalProfileOpen(true);
            }}
            text="Cancel"
          ></ButtonDefault>
          <Button
            type="text"
            size="large"
            disabled={disabledButton}
            htmlType="submit"
            className="w-[81px]"
            loading={loadingSubmit}
          >
            Save
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default ModalChangePassword;
