import React from "react";
import { ImgBackgroundWallet } from "../../../../../assets/image";
import { useRecoilValue } from "recoil";
import { typeViewState } from "../../../../../adapter/recoil/common/state";
import { TYPE_DEVICE } from "../../../../../constants";

const WalletConnectionContainer = ({ children }) => {
  const typeDevice = useRecoilValue(typeViewState);

  return (
    <div
      className="relative rounded-xl lg:p-8 p-4 mt-8 bg-contain bg-right"
      style={
        typeDevice == TYPE_DEVICE.DESKTOP
          ? {
              background:
                "radial-gradient(608.13% 75.38% at 27.51% 65.96%, #6536DA 0%, #05000E 87.65%)",
            }
          : {
              background:
                "radial-gradient(1051.18% 129.71% at 27.51% 65.96%, #6536DA 0%, #05000E 87.65%)",
            }
      }
    >
      <div className="lg:text-32px text-18px font-bold text-white mb-4">
        Wallet Connection
      </div>
      <div className="max-w-[672px] lg:mb-[18px] mb-[10px] text-white">
        To exchange your Nodepay Points for tokens, connect and verify your
        wallet. Complete the 3-step process to unlock token rewards.
      </div>
      <div className="lg:flex-nowrap flex-wrap flex lg:gap-4 gap-3 relative z-50">
        {children}
      </div>
      <img
        className="w-full 2xl:w-7/12 absolute right-0 bottom-0"
        src={ImgBackgroundWallet}
      />
    </div>
  );
};

export default WalletConnectionContainer;
