import { Button, Form, Input, Result } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useEffect, useState } from "react";
import { IconDownload } from "../../assets/icon";
import { ImgAniLoading, ImgLoading } from "../../assets/image";
import ReactJson from "react-json-view";
import classNames from "classnames";
import { useRecoilValue } from "recoil";
import toastFn from "../../shared/components/ToastNoti";
import ScrapegraphService from "../../adapter/services/scrapegraph";
import { MAP_PROTOTYPE_INFO, PROTOTYPE_SCRAPEGRAPH } from "./props";
import { DownloadOutlined } from "@ant-design/icons";

const ScrapegraphMainForm = () => {
  const [form] = useForm();
  const [disabledButton, setDisabledButton] = useState<boolean>(true);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [showTableResult, setShowTableResult] = useState<boolean>(false);
  const [result, setResult] = useState<any>();
  const [prototype, setPrototype] = useState<any>();

  const values = Form.useWatch([], form);

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setDisabledButton(false))
      .catch(() => setDisabledButton(true));
  }, [form, values]);

  const handleSetStatePrototype = (type) => {
    setResult(MAP_PROTOTYPE_INFO[type]);
    setLoadingSubmit(false);
    setDisabledButton(false);
  };

  const handleTriggerPrototype = (prompt, type) => {
    setPrototype(type);
    if (prompt.indexOf("test") !== -1) {
      setTimeout(() => {
        handleSetStatePrototype(type);
      }, 3000);
    } else {
      setTimeout(() => {
        handleSetStatePrototype(type);
      }, 8000);
    }
  };

  const handleSubmit = (values) => {
    setResult(undefined);
    setDisabledButton(true);
    setShowTableResult(true);
    setLoadingSubmit(true);
    let url = values.url?.toString()?.trim();
    let prompt = values.prompt;
    let result = undefined;
    let type;
    if (url === "https://www.kaggle.com") {
      type =
        prompt ===
        "Collect recent financial reports, earnings calls, and related articles of top 5 tech company from website list above. The data should include company name, report date, key financial figures, market analysis, and expert opinions. Output as CSV file with structured metadata"
          ? PROTOTYPE_SCRAPEGRAPH.TYPE2
          : PROTOTYPE_SCRAPEGRAPH.TYPE1;
      handleTriggerPrototype(prompt, type);
    } else if (
      url?.indexOf(
        "https://www.amazon.com/gp/browse.html?rw_useCurrentProtocol=1&node=23901427011&ref_=amb_link_NbOhBNWmQBuHjAXBtSq_4g_1"?.slice(
          0,
          20
        )
      ) !== -1
    ) {
      type = PROTOTYPE_SCRAPEGRAPH.TYPE3;
      handleTriggerPrototype(prompt, type);
    } else {
      setPrototype(undefined);
      setShowTableResult(true);
      setLoadingSubmit(true);
      ScrapegraphService.crawlData(values)
        .then((res) => {
          if (res.code === 0 && res.data) {
            setResult(res.data.result);
          } else {
            setShowTableResult(false);
            toastFn({ message: "Error", description: res.msg });
          }
        })
        .catch((error) => {
          setShowTableResult(false);
          toastFn({
            message: "Error",
            description: error.msg || "Something went error",
          });
        })
        .finally(() => {
          setLoadingSubmit(false);
          setDisabledButton(false);
        });
    }
  };
  const handleDownloadFile = (url, fileName) => {
    const link = document.createElement("a");
    document.body.appendChild(link);
    link.href = url;
    link.setAttribute("download", fileName);
    link.click();
    document.body.removeChild(link);
  };
  const handleDownloadJsonFile = () => {
    const fileName = "Result.json";
    const data = new Blob([result], { type: "text/json" });
    const jsonURL = window.URL.createObjectURL(data);
    handleDownloadFile(jsonURL, fileName);
  };
  return (
    <div className="lg:p-8 p-4 flex flex-col items-center justify-center">
      <div className="w-full mb-8">
        <Form
          form={form}
          name="basic"
          onFinish={handleSubmit}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item
            label="Link to crawl"
            className="mb-3"
            name="url"
            validateDebounce={500}
            rules={[
              {
                required: true,
                validateTrigger: "onSubmit",
              },
            ]}
          >
            <Input
              size="large"
              placeholder="For example: https://coinmarketcap.com/"
            />
          </Form.Item>
          <Form.Item
            label="What to get"
            className="mb-7"
            name="prompt"
            validateDebounce={500}
            rules={[
              {
                required: true,
                validateTrigger: "onSubmit",
              },
            ]}
          >
            <Input
              size="large"
              placeholder="For example: Read me the summary of the news"
            />
          </Form.Item>
          <Button
            htmlType="submit"
            disabled={disabledButton}
            //loading={loadingSubmit}
            className="px-6 py-[10px] flex items-center h-10"
            type="text"
          >
            <span className="text-14px font-bold">Run</span>
          </Button>
        </Form>
      </div>
      <div className="rounded-lg w-full" hidden={!showTableResult}>
        <div className="flex justify-between items-center bg-purple-light px-4 py-[14px] rounded-tl-lg rounded-tr-lg">
          <span className="font-bold">Result</span>
          <div
            className={classNames("flex items-center", {
              "cursor-pointer": result,
              "cursor-not-allowed": !result,
              hidden: prototype,
            })}
            onClick={() => {
              if (result) {
                handleDownloadJsonFile();
              }
            }}
          >
            <img src={IconDownload} className="w-6 h-6 mr-2 " />
            <span>Download</span>
          </div>
        </div>
        <div className="w-full min-h-[195px] h-fit bg-white rounded-bl-lg rounded-br-lg">
          {result ? (
            <div className="p-4">
              {prototype ? (
                <Result
                  status="success"
                  title="Completed!"
                  subTitle={
                    <span className="text-16px text-neutral-110">
                      {result.text}
                    </span>
                  }
                  extra={[
                    <div className="flex items-center justify-center">
                      <Button
                        icon={<DownloadOutlined />}
                        type="text"
                        key="download"
                        className="flex items-center"
                        size="large"
                        onClick={() => {
                          handleDownloadFile(result.link, result.fileName);
                        }}
                      >
                        {result.cta}
                      </Button>
                    </div>,
                  ]}
                />
              ) : (
                <ReactJson src={JSON.parse(result)} />
              )}
            </div>
          ) : (
            <div
              hidden={result}
              className="w-full h-full min-h-[195px] flex flex-col items-center justify-center"
            >
              {/* <img
                src={ImgLoading}
                className="inline w-14 h-14 text-gray-200 animate-spin dark:text-gray-600 mb-2"
              /> */}
              <img width={80} height={80} src={ImgAniLoading} />
              <span className="mt-4 font-bold text-16px">
                Scraping in process...
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ScrapegraphMainForm;
