import { ReactComponent as IconNext } from "../../../assets/icon/ic-next.svg";
import ROUTE from "../../../constants/route";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { accountState, tokenState } from "../../../adapter/recoil/common/state";
import { ReactComponent as IconBackToTop } from "../../../assets/icon/ic-back-to-top.svg";

const ResponsiveFooter = () => {
  const navigate = useNavigate();
  const [accountInfo, setAccountInfo] = useRecoilState(accountState);
  const setToken = useSetRecoilState(tokenState);

  const handleLogout = () => {
    navigate(ROUTE.LOGIN);
    navigate(0);
    localStorage.removeItem("np_token");
    localStorage.removeItem("np_webapp_token");
    sessionStorage.removeItem("np_webapp_token");
    setAccountInfo(undefined);
    setToken(undefined);
  };
  return (
    <>
      <div className="mt-6 mb-10">
        <div className="rounded-lg p-4 border border-solid border-neutral-110 bg-neutral-10">
          <div className="text-14px font-bold text-purple-primary mb-[10px]">
            Learn how to optimize your experience
          </div>
          <div
            className="flex justify-between items-center cursor-pointer"
            onClick={() => window.open("https://nodepay.ai/#blogs", "_blank")}
          >
            <div className="text-neutral-110">Read Blog</div>
            <div className="w-7 h-7 rounded-lg ant-btn-primary flex items-center justify-center">
              <IconNext />
            </div>
          </div>
        </div>
      </div>
      <div
        className="cursor-pointer fixed bottom-[96px] right-4 w-10 h-10 rounded-lg bg-black flex items-center justify-center"
        onClick={() => {
          window.scrollTo({
            top: 0,
            behavior: "smooth", // Enables smooth scrolling animation
          });
        }}
      >
        <IconBackToTop className="w-4 h-4" />
      </div>
    </>
  );
};

export default ResponsiveFooter;
