import { atom, selector } from "recoil";
import ScrapegraphService from "../../services/scrapegraph";

export const promptSampleState = atom({
  key: "promptSampleState",
  default: selector({
    key: "rewardReferralInfoSeletor",
    get: async () => {
      let res = await ScrapegraphService.getSample();
      if (res.code === 0) {
        return res.data;
      }
      return [];
    },
  }),
});
