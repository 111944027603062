import { Drawer, Layout, Modal } from "antd";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useRecoilRefresher_UNSTABLE,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import {
  accountState,
  infoSocialState,
  isInTimeMaintain,
  tokenState,
  typeViewState,
} from "../../../adapter/recoil/common/state";
import { ReactComponent as IconLogo } from "../../../assets/icon/ic-logo-mobile.svg";
import { ReactComponent as IconEarningActive } from "../../../assets/icon/ic-earning-active.svg";
import { ReactComponent as IconEarningInactive } from "../../../assets/icon/ic-earning-inactive.svg";
import { ReactComponent as IconDashboardInactive } from "../../../assets/icon/ic-menu-dashboard-inactive.svg";
import { ReactComponent as IconDashboardMobileActive } from "../../../assets/icon/ic-menu-dashboard-mobile-active.svg";
import { ReactComponent as IcMenuHotWhite } from "../../../assets/icon/ic-menu-hot-white.svg";
import { ReactComponent as IcMenuHot } from "../../../assets/icon/ic-menu-hot.svg";
import { ReactComponent as IconMenuMedalMobile } from "../../../assets/icon/ic-menu-medal-active-mobile.svg";
import { ReactComponent as IconMenuMedalInactive } from "../../../assets/icon/ic-menu-medal-inactive.svg";
import { ReactComponent as IconMenuMoreActive } from "../../../assets/icon/ic-menu-more-active.svg";
import { ReactComponent as IconMenuMoreInactive } from "../../../assets/icon/ic-menu-more-inactive.svg";
import { ReactComponent as IconRewardInactive } from "../../../assets/icon/ic-menu-reward-inactive.svg";
import { ReactComponent as IconRewardMobileInactive } from "../../../assets/icon/ic-menu-reward-mobile-inactive.svg";
import { ReactComponent as IconReferralMobileActive } from "../../../assets/icon/ic-referral-mobile-active.svg";
import { ReactComponent as IconReferral } from "../../../assets/icon/ic-referral.svg";
import { ReactComponent as IconTransHistoryInactive } from "../../../assets/icon/ic-trans-history-inactive.svg";
import { ReactComponent as IconTransHistoryMobileActive } from "../../../assets/icon/ic-trans-history-mobile-active.svg";
import ROUTE from "../../../constants/route";
import { MAP_MENU, MENU_TYPE } from "../menu_props";
const { Header, Content, Footer, Sider } = Layout;

import { IconCloseModal, IconCloseModalWhite } from "../../../assets/icon";
import { ImgLogo } from "../../../assets/image";
import { TYPE_DEVICE } from "../../../constants";
import Banner from "../../components/Banner";
import ResponsiveFooter from "../../components/ResponsiveFooter";
import ModalPopupTelegram from "../ModalPopupTelegram";
import ModalPopupX from "../ModalPopupX";
import ModalUserProfile from "../ModalUserProfile";
import "./style.less";

const LayoutResponsiveContainer = ({
  children,
  classNameHeader,
  classNameContent = "",
}: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const typeDevice = useRecoilValue(typeViewState);
  const [accountInfo, setAccountInfo] = useRecoilState(accountState);
  const [isModalProfileOpen, setIsModalProfileOpen] = useState(false);
  const [isModalPopupX, setIsModalPopupX] = useState(false);
  const setToken = useSetRecoilState(tokenState);
  const isInTimeMaintainance = useRecoilValue(isInTimeMaintain);
  const refreshSocialInfo = useRecoilRefresher_UNSTABLE(infoSocialState);
  const [isModalPopupTelegram, setIsModalPopupTelegram] = useState(false);
  const [openSheetMore, setOpenSheetMore] = useState(false);

  useEffect(() => {
    if (accountInfo) {
      let triggerShowX = localStorage.getItem(
        `np_trigger_${accountInfo?.uid}_x`
      );
      if (!triggerShowX) {
        setIsModalPopupX(true);
      }
      // let triggerShowTele = localStorage.getItem(
      //   `np_trigger_${accountInfo?.uid}_tele`
      // );
      // if (!triggerShowTele) {
      //   setIsModalPopupTelegram(true);
      // }
    }
  }, [accountInfo]);

  const renderIconMenu = (menuType, active) => {
    let logo;
    switch (menuType) {
      case MENU_TYPE.DASHBOARD:
        logo = active ? (
          <IconDashboardMobileActive />
        ) : (
          <IconDashboardInactive />
        );
        break;
      case MENU_TYPE.REFERRAL:
        logo = active ? <IconReferralMobileActive /> : <IconReferral />;
        break;
      case MENU_TYPE.TRANSACTION_HISTORY:
        logo = active ? (
          <IconTransHistoryMobileActive />
        ) : (
          <IconTransHistoryInactive />
        );
        break;
      case MENU_TYPE.MEDAL:
        logo = active ? <IconMenuMedalMobile /> : <IconMenuMedalInactive />;
        break;
      case MENU_TYPE.MORE:
        logo = openSheetMore ? (
          <IconMenuMoreActive />
        ) : (
          <IconMenuMoreInactive />
        );
        break;
      case MENU_TYPE.EARNING:
        logo = active ? <IconEarningActive /> : <IconEarningInactive />;
        break;
      case MENU_TYPE.REWARD:
        logo = active ? <IconRewardMobileInactive /> : <IconRewardInactive />;
        break;
    }
    return logo;
  };

  const renderIconBottomsheet = (menuType, isActive) => {
    if (isActive) {
      return (
        <img
          width={24}
          height={24}
          className="img-menu-active"
          src={MAP_MENU[menuType]?.active}
        />
      );
    }
    return (
      <img
        width={24}
        height={24}
        className="img-menu-inactive"
        src={MAP_MENU[menuType]?.inactive}
      />
    );
  };
  const renderMenu = (type) => {
    if (location.pathname === MAP_MENU[type].path) {
      return (
        <div
          key={type}
          className="inline-flex flex-col justify-between hover:bg-gray-50 group"
          onClick={() => {
            if (type == MENU_TYPE.MORE) {
              setOpenSheetMore(true);
            } else navigate(MAP_MENU[type]?.path);
          }}
        >
          <div className="w-full h-1 rounded bg-purple"></div>
          <div className="h-full justify-center flex flex-col items-center px-1">
            <div className={`w-6 h-6 mb-2 `}>{renderIconMenu(type, true)}</div>
            <span className="text-12px text-purple font-bold group-hover:text-purple text-center">
              {MAP_MENU[type].name}
            </span>
          </div>
        </div>
      );
    } else {
      return (
        <div
          key={type}
          className="inline-flex flex-col justify-between hover:bg-gray-50 group menu-mobile-item"
          onClick={() => {
            if (type == MENU_TYPE.MORE) {
              setOpenSheetMore(true);
            }
            navigate(MAP_MENU[type]?.path);
          }}
        >
          <div className="h-full justify-center flex flex-col items-center  px-1">
            <div className="w-6 h-6 mb-2">{renderIconMenu(type, false)}</div>
            <span className="text-12px text-[#292A31] group-hover:text-purple text-center">
              {MAP_MENU[type].name}
            </span>
          </div>
        </div>
      );
    }
  };
  const handleLogout = () => {
    navigate(ROUTE.LOGIN);
    navigate(0);
    localStorage.removeItem("np_token");
    localStorage.removeItem("np_webapp_token");
    sessionStorage.removeItem("np_webapp_token");
    setAccountInfo(undefined);
    setToken(undefined);
  };

  return (
    <>
      <Layout className="h-full min-h-screen w-screen max-w-full">
        {isInTimeMaintainance ? (
          <div
            className={classNames(
              "bg-[#FDF77D] max-w-screen lg:h-[56px] h-fit flex flex-wrap items-center justify-center lg:p-0 p-3"
            )}
          >
            <span className="text-neutral-110 font-bold">
              We apologize for the inconvenience caused by maintenance. Enjoy a
              voucher code 'compensation' as our token of gratitude for your
              patience.
            </span>
          </div>
        ) : (
          <Banner accountInfo={accountInfo} typeDevice={typeDevice} />
        )}

        <Header
          className={classNames(
            "bg-white py-2 px-4  max-w-screen",
            "flex justify-between items-center",
            {
              hidden: !accountInfo,
            }
          )}
        >
          <IconLogo />
          <div
            className={classNames(
              "rounded-full w-10 h-10  cursor-pointer z-50",
              {
                hidden: !accountInfo?.avatar,
              }
            )}
            onClick={() => {
              if (accountInfo) {
                setIsModalProfileOpen(true);
                refreshSocialInfo();
              }
            }}
          >
            <img
              width={40}
              height={40}
              className="rounded-full"
              src={accountInfo?.avatar}
            />
          </div>
        </Header>
        <Content
          className={classNames(
            "bg-[#f1f3ff] min-h-full grow p-4 max-w-screen pb-[100px]",
            classNameContent
          )}
        >
          <div className="text-purple font-bold text-24px mb-4">
            Hello{accountInfo?.name ? `, ${accountInfo?.name}!` : "!"}
          </div>
          {children}
          <ResponsiveFooter />
        </Content>

        <div
          className={classNames(
            "fixed  z-50 w-full h-[84px] bg-white border-t border-[#593FDE] rounded-tl-2xl rounded-tr-2xl",
            {
              "max-w-[576px] bottom-0 left-1/2 transform -translate-x-1/2":
                typeDevice == TYPE_DEVICE.TABLET,
              "bottom-0 left-0": typeDevice !== TYPE_DEVICE.TABLET,
            }
          )}
        >
          <div className="grid h-full grid-cols-5 mx-1 px-4 font-medium cursor-pointer">
            {[
              MENU_TYPE.DASHBOARD,
              MENU_TYPE.REFERRAL,
              MENU_TYPE.REWARD,
              MENU_TYPE.MEDAL,
              //MENU_TYPE.TRANSACTION_HISTORY,
              MENU_TYPE.MORE,
            ].map((item, idx) => renderMenu(item))}
          </div>
        </div>
      </Layout>
      <Modal
        title="User Profile"
        open={isModalProfileOpen}
        closeIcon={<img width={24} height={24} src={IconCloseModal} />}
        className="modal-profile-user"
        //onOk={handleOk}
        onCancel={() => setIsModalProfileOpen(false)}
        footer={null}
        width={600}
      >
        <ModalUserProfile
          setIsModalProfileOpen={setIsModalProfileOpen}
          accountInfo={accountInfo}
          handleLogout={handleLogout}
        />
      </Modal>
      <Modal
        title=""
        open={isModalPopupX}
        width={typeDevice == TYPE_DEVICE.MOBILE ? 380 : 460}
        closeIcon={<img width={24} height={24} src={IconCloseModalWhite} />}
        className="modal-popup-x"
        footer={null}
        onCancel={() => {
          localStorage.setItem(`np_trigger_${accountInfo?.uid}_x`, "checked");
          setIsModalPopupX(false);
        }}
      >
        <ModalPopupX typeDevide={typeDevice}></ModalPopupX>
      </Modal>
      <Modal
        title=""
        open={isModalPopupTelegram}
        closeIcon={<img width={24} height={24} src={IconCloseModalWhite} />}
        className="modal-popup-x"
        footer={null}
        width={typeDevice == TYPE_DEVICE.MOBILE ? 360 : 480}
        onCancel={() => {
          localStorage.setItem(
            `np_trigger_${accountInfo?.uid}_tele`,
            "checked"
          );
          setIsModalPopupTelegram(false);
        }}
      >
        <ModalPopupTelegram typeDevide={typeDevice}></ModalPopupTelegram>
      </Modal>
      <Drawer
        title=""
        placement="bottom"
        open={openSheetMore}
        key="bottom"
        closable={false}
        destroyOnClose={true}
        onClose={() => setOpenSheetMore(false)}
        style={{
          borderTopLeftRadius: "16px",
          borderTopRightRadius: "16px",
          padding: "16px 0",
          minHeight: "100px",
          height: "fit-content",
        }}
        styles={{
          body: {
            padding: "0",
            maxHeight: "fit-content",
            minHeight: "fit-content",
          },
        }}
      >
        <div className="px-4 flex flex-col">
          {[MENU_TYPE.EARNING, MENU_TYPE.TRANSACTION_HISTORY].map(
            (item, idx) => {
              let isActive = location.pathname === MAP_MENU[item].path;
              return (
                <div
                  className={classNames(
                    "cursor-pointer max-w-full h-[48px] flex items-center pl-3 rounded-lg",
                    {
                      "bg-purple": isActive,
                    }
                  )}
                  onClick={() => {
                    navigate(MAP_MENU[item]?.path);
                    setOpenSheetMore(false);
                  }}
                >
                  {renderIconBottomsheet(item, isActive)}
                  <span
                    className={classNames("mx-2", { "text-white": isActive })}
                  >
                    {MAP_MENU[item].name}
                  </span>
                  {item == MENU_TYPE.EARNING ? (
                    isActive ? (
                      <IcMenuHotWhite className=" ml-2 min-w-[18px] min-h-[22px]" />
                    ) : (
                      <IcMenuHot className=" ml-2 min-w-[18px] min-h-[22px]" />
                    )
                  ) : (
                    <></>
                  )}
                </div>
              );
            }
          )}
        </div>
      </Drawer>
    </>
  );
};

export default LayoutResponsiveContainer;
