import {
  AGE_ENUM,
  DISCORD_ROLE,
  KIND_TYPE,
  MISSION_CODE,
  MISSION_CODE_PARAM,
  NETWORK_QUALITY,
  TRANSACTION_TYPE,
  WALLET_STEP,
} from "./enum";

export const DOMAIN_API_URL = process.env.REACT_APP_API_URL;
export const ReCAPTCHA_PUBLIC_KEY = process.env.REACT_APP_ReCAPTCHA_PUBLIC_KEY;
export const PATTERN = {
  EMAIL: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
};

export const currentDate = new Date().getTime();

export const WIDTH_DEVICE = {
  MOBILE: 768,
  TABLET: 1023,
};

export enum TYPE_DEVICE {
  MOBILE = 1,
  TABLET = 2,
  DESKTOP = 3,
}
export const LINK_DOWNLOAD_EXT =
  "https://chromewebstore.google.com/detail/nodepay-extension/lgmpfmgeabnnlemejacfljbmonaomfmm";

export const MAP_KIND_TEXT = {
  [KIND_TYPE.NONE]: "",
  [KIND_TYPE.ADD]: "+",
  [KIND_TYPE.SUBTRACT]: "-",
};

export const NETWORK_QUALITY_COLOR = {
  [NETWORK_QUALITY.DISCONNECTED]: "neutral-110",
  [NETWORK_QUALITY.HIGH]: "green",
  [NETWORK_QUALITY.MEDIUM]: "yellow",
  [NETWORK_QUALITY.LOW]: "red",
};

export const MAP_MISSION_ACTION_TEXT = {
  [MISSION_CODE.VERIFY_DISCORD]: "Connect Discord",
  [MISSION_CODE.VERIFY_X]: "Connect Twitter",
  [MISSION_CODE.VERIFY_EMAIL]: "Send verification email",
  [MISSION_CODE.FOLLOW_X]: "Verify Twitter",
  [MISSION_CODE.JOIN_DISCORD]: "Join Discord",
  [MISSION_CODE.QNA_CHALLENGE_SURVEY]: "Answer",
  [MISSION_CODE.QNA_CHALLENGE_SURVEY_SET_2]: "Answer",
  [MISSION_CODE.QNA_CHALLENGE_SURVEY_SET_3]: "Answer",
};

export const MAP_MISSION_ACTION_TEXT_INSTRUCTION = {
  [MISSION_CODE.VERIFY_DISCORD]: "Connect your Discord",
  [MISSION_CODE.VERIFY_X]: "Connect your Twitter",
  [MISSION_CODE.VERIFY_EMAIL]: "Verify your Email",
  [MISSION_CODE.FOLLOW_X]: "Follow Nodepay",
  [MISSION_CODE.JOIN_DISCORD]: "Join Discord",
  [MISSION_CODE.QNA_CHALLENGE_SURVEY]: "Answer the question",
  [MISSION_CODE.QNA_CHALLENGE_SURVEY_SET_2]: "Answer the question",
  [MISSION_CODE.QNA_CHALLENGE_SURVEY_SET_3]: "Answer the question",
};
export const MAP_MISSION_ACTION_TEXT_COMPLETED = {
  [MISSION_CODE.VERIFY_DISCORD]: "Connected",
  [MISSION_CODE.VERIFY_X]: "Connected",
  [MISSION_CODE.VERIFY_EMAIL]: "Sent",
  [MISSION_CODE.FOLLOW_X]: "Followed",
  [MISSION_CODE.JOIN_DISCORD]: "Joined",
  [MISSION_CODE.QNA_CHALLENGE_SURVEY]: "Answered",
  [MISSION_CODE.QNA_CHALLENGE_SURVEY_SET_2]: "Answered",
  [MISSION_CODE.QNA_CHALLENGE_SURVEY_SET_3]: "Answered",
};
export const MAP_TEXT_AGE = {
  [AGE_ENUM.UNDER_18]: "Under 18",
  [AGE_ENUM.BETWEEN_18_24]: "18-24",
  [AGE_ENUM.BETWEEN_25_34]: "25-34",
  [AGE_ENUM.BETWEEN_35_44]: "35-44",
  [AGE_ENUM.BETWEEN_45_54]: "45-54",
  [AGE_ENUM.BETWEEN_55_64]: "55-64",
  [AGE_ENUM.ABOVE_65]: "Above 65",
};

export const ARR_AGE_RANGE = [
  AGE_ENUM.UNDER_18,
  AGE_ENUM.BETWEEN_18_24,
  AGE_ENUM.BETWEEN_25_34,
  AGE_ENUM.BETWEEN_35_44,
  AGE_ENUM.BETWEEN_45_54,
  AGE_ENUM.BETWEEN_55_64,
  AGE_ENUM.ABOVE_65,
];

export const MAP_ERROR_EXIST_TEXT = {
  [MISSION_CODE_PARAM.VERIFY_DISCORD]:
    "Your Discord is linked to another Nodepay account",
  [MISSION_CODE_PARAM.VERIFY_X]: "Your X is linked to another Nodepay account",
  [MISSION_CODE_PARAM.JOIN_DISCORD]: "Join Discord failed",
};

export const MAP_ERROR_FAIL_TEXT = {
  [MISSION_CODE_PARAM.VERIFY_DISCORD]: "Verify failed",
  [MISSION_CODE_PARAM.VERIFY_X]: "Verify failed",
  [MISSION_CODE_PARAM.JOIN_DISCORD]: "Join failed",
};

export const MAP_DISCORD_ROLE = {
  [DISCORD_ROLE.GENERAL_NODE]: "General Node",
  [DISCORD_ROLE.NETWORK_NODE]: "Network Node",
  [DISCORD_ROLE.I_WAS_EARLY]: "I was early",
  [DISCORD_ROLE.NEW_FOUNDER]: "New founder",
  [DISCORD_ROLE.VETERAN_FOUNDER]: "Veteran Founder",
};

export const MAP_TRANSACTION_TYPE = {
  [TRANSACTION_TYPE.ALL]: "All Transaction Type",
  [TRANSACTION_TYPE.REFERRAL]: "Referral",
  [TRANSACTION_TYPE.ACTIVITY]: "Activity",
  [TRANSACTION_TYPE.EARNING]: "Earning",
  [TRANSACTION_TYPE.MOBILE_REWARD]: "Mobile resources utilizer reward",
  [TRANSACTION_TYPE.PARTNER_REWARD]: "Partner reward",
};

export const MAP_WALLET_TEXT = {
  DONE: {
    [WALLET_STEP.STEP1]: "Verified",
    [WALLET_STEP.STEP2]: "Connected",
    [WALLET_STEP.STEP3]: "Verified",
  },
  ACTION: {
    [WALLET_STEP.STEP1]: "Verify",
    [WALLET_STEP.STEP2]: "Connect",
    [WALLET_STEP.STEP3]: "Confirm",
  },
};

export const LINK_MOBILE = {
  ANDROID: "https://play.google.com/store/apps/details?id=ai.nodepay.mobileapp",
  IOS: "https://apps.apple.com/vn/app/nodepay/id6689522455",
};

export const isProduction =
  window.location.origin.indexOf("https://app.nodepay.ai") !== -1
    ? true
    : false;
