import { useNavigate } from "react-router-dom";
import useQuery from "../../../../../shared/hook/useQuery";
import { useEffect, useState } from "react";
import WalletService from "../../../../../adapter/services/wallet";
import LayoutAuthContainer from "../../../../../shared/layout/LayoutAuth";
import ResultView from "../../../../../shared/components/Result";
import ROUTE from "../../../../../constants/route";
import { ReactComponent as IconCopyWallet } from "../../../../../assets/icon/ic-copy-wallet-address.svg";
import copy from "copy-to-clipboard";
import toastFn from "../../../../../shared/components/ToastNoti";
import { ImgAniLoading } from "../../../../../assets/image";

export function formatWalletAddress(str) {
  if (!str || str?.length <= 4) {
    return str;
  }
  const firstFour = str.slice(0, 4);
  const lastFour = str.slice(-4);
  return `${firstFour}...${lastFour}`;
}

const VerifyWalletConnectionView = () => {
  const navigate = useNavigate();
  const query: any = useQuery();
  const [hasToken, setHasToken] = useState<boolean>(false);
  const [dataVerify, setDataVerify] = useState<any>();
  useEffect(() => {
    if (query?.token) {
      setHasToken(true);
    }
  }, [query?.token]);

  useEffect(() => {
    if (hasToken) {
      WalletService.verifyWallet({ token: query.token })
        .then((res) => {
          setDataVerify(res.data);
          if (res.code !== 0)
            toastFn({ message: "Error", description: res.msg });
        })
        .catch((error) => {
          toastFn({
            message: "Error",
            description: error.msg || "Something went error",
          });
        });
    }
  }, [hasToken]);

  const handleCopy = () => {
    if (!dataVerify) return;
    let result = copy(dataVerify?.wallet_address);
    if (result) {
      toastFn({
        type: "success",
        message: (
          <div className="flex flex-col">
            <div className="mb-0.5 text-16px text-neutral-110">
              Wallet address copied!
            </div>
            <div className="text-14px text-neutral-110">
              {dataVerify?.wallet_address}
            </div>
          </div>
        ),
      });
    } else {
      toastFn({ message: "Error", description: "Something went error" });
    }
  };

  if (!dataVerify) {
    return (
      <LayoutAuthContainer classNameContent="flex lg:items-center justify-center py-6 px-4">
        <div className="w-full h-full min-h-[195px] flex items-center justify-center">
          <img width={80} height={80} src={ImgAniLoading} />
        </div>
      </LayoutAuthContainer>
    );
  }

  return (
    <LayoutAuthContainer classNameContent="flex lg:items-center justify-center py-6 px-4">
      <ResultView
        elementContent={
          <div className="w-full mt-8 mb-8 flex flex-col items-center justify-center">
            <div className="lg:text-24px text-18px lg:mb-3 mb-2">
              {dataVerify?.success
                ? "Wallet is verified"
                : "Wallet is not verified"}
            </div>
            <div className="text-16px lg:mb-8 mb-6">
              {dataVerify?.success
                ? "Wallet connection successful"
                : "Wallet connection unsuccessful. Please try again."}
            </div>
            {dataVerify?.wallet_address && (
              <div className="px-4 py-3 w-full rounded-lg bg-[#F2F4F8] flex items-center justify-between">
                Wallet Address
                <div
                  className="shrink-0 text-14px flex items-center"
                  onClick={handleCopy}
                >
                  {formatWalletAddress(dataVerify?.wallet_address)}
                  <IconCopyWallet />
                </div>
              </div>
            )}
          </div>
        }
        success={dataVerify?.success}
        btnText="Return to Dashboard"
        handleClickBtn={() => navigate(ROUTE.DASHBOARD)}
      />
    </LayoutAuthContainer>
  );
};

export default VerifyWalletConnectionView;
