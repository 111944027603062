import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { accountState, tokenState } from "../adapter/recoil/common/state";
import AuthService from "../adapter/services/auth";
import { TYPE_DEVICE, WIDTH_DEVICE } from "../constants";
import ROUTE from "../constants/route";
import ResponsiveView from "./components/ResponsiveView";
import useCurrentWidth from "./hook/useCurrentWidth";
import LayoutContainer from "./layout";

export const ProtectedRoute = ({ children, redirectTo }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const screenWidth = useCurrentWidth();

  const [accountInfo, setAccountInfo] = useRecoilState(accountState);
  let [authenticated, setAuthenticated] = useState(false);
  const [token, setToken] = useRecoilState(tokenState);
  useEffect(() => {
    if (!token) {
      let tokenInfo =
        sessionStorage.getItem("np_webapp_token") ||
        localStorage.getItem("np_webapp_token");
      if (tokenInfo) {
        setToken(tokenInfo);
      } else {
        navigate(redirectTo);
        return;
      }
    }
    if (!accountInfo) {
      AuthService.postSession({})
        .then((res) => {
          if (res.code === 0) {
            setAccountInfo(res.data);
            setAuthenticated(true);
          } else {
            navigate(redirectTo);
            return;
          }
        })
        .catch((error) => {
          console.log(error);
          navigate(redirectTo);
          return;
        });
    }
  }, [token, accountInfo, redirectTo]);

  if (
    accountInfo &&
    [ROUTE.SCRAPEGRAPH, ROUTE.OAUTH_PERMISSION].includes(location.pathname)
  ) {
    return children;
  }

  if (accountInfo && screenWidth < WIDTH_DEVICE.TABLET) {
    return (
      <ResponsiveView
        type={
          screenWidth < WIDTH_DEVICE.MOBILE
            ? TYPE_DEVICE.MOBILE
            : TYPE_DEVICE.TABLET
        }
      >
        {children}
      </ResponsiveView>
    );
  }

  return authenticated ? <LayoutContainer>{children}</LayoutContainer> : null;
};
