import { Dropdown, Layout } from "antd";
import classNames from "classnames";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useLocation, useNavigate } from "react-router-dom";
import { useMemo, useState } from "react";
import { accountState, tokenState } from "../../../adapter/recoil/common/state";
import ROUTE from "../../../constants/route";
import { ImgLogo } from "../../../assets/image";
import { IconCopyBlack, IconMenuScrapegraph } from "../../../assets/icon";
import ScrapegraphMainForm from "../../../module/scapegraph";
import { ReactComponent as IconLogout } from "../../../assets/icon/ic-logout.svg";
import { ReactComponent as IconSwitch } from "../../../assets/icon/ic-switch.svg";
import { promptSampleState } from "../../../adapter/recoil/scrapegraph/state";
import copy from "copy-to-clipboard";
import toastFn from "../../components/ToastNoti";
import "./style.css";

const { Header, Content, Footer, Sider } = Layout;

const LayoutScrapegraphContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [accountInfo, setAccountInfo] = useRecoilState(accountState);
  const [isModalProfileOpen, setIsModalProfileOpen] = useState(false);
  const [isModalPopupX, setIsModalPopupX] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const setToken = useSetRecoilState(tokenState);
  const sampleData = useRecoilValue(promptSampleState);

  console.log("sampleDatasampleData", sampleData);
  const handleLogout = () => {
    navigate(ROUTE.LOGIN);
    navigate(0);
    localStorage.removeItem("np_token");
    localStorage.removeItem("np_webapp_token");
    sessionStorage.removeItem("np_webapp_token");
    setAccountInfo(undefined);
    setToken(undefined);
  };

  const handleGotoDashboard = () => {
    navigate(ROUTE.DASHBOARD);
  };
  const ARRAY_USAGE_EXAMPLE = useMemo(() => {
    return (
      sampleData?.map((item, idx) => {
        let index = idx + 1;
        return {
          title: `Prompt ${index}`,
          desc: item.prompt_content,
        };
      }) || []
    );
  }, [sampleData]);

  const items = [
    {
      label: "Transfer to Nodepay",
      key: "1",
      icon: <IconSwitch />,
      onClick: handleGotoDashboard,
    },
    {
      label: "Log out",
      key: "2",
      icon: <IconLogout />,
      onClick: handleLogout,
    },
  ];

  const handleCopySample = (textSample) => {
    let result = copy(textSample);

    if (result) {
      toastFn({
        type: "success",
        message: (
          <div className="flex flex-col">
            <div className="mb-0.5 text-16px text-neutral-110">
              Content copied!
            </div>
            <div className="text-14px text-neutral-110">{textSample}</div>
          </div>
        ),
      });
    } else {
      toastFn({ message: "Error", description: "Something went error" });
    }
  };
  return (
    <>
      <Layout className="layout-scrapegraph flex flex-col h-full">
        <Sider
          className="sider-menu"
          collapsed={false}
          theme="light"
          width={280}
        >
          <div className="w-full md:h-20 h-[54px] md:py-[25px] py-3 md:px-5 px-3 flex justify-between items-center">
            <img className="w-[190px] h-[30px]" src={ImgLogo} alt="logo" />
            <img className="w-6 h-6" src={IconMenuScrapegraph} />
          </div>
          <div className="flex flex-col h-full md:py-6 py-3 md:px-4 px-3 menu">
            <div className="text-24px md:mb-8 mb-4">Usage Examples</div>
            {ARRAY_USAGE_EXAMPLE.map((item) => (
              <div className="flex flex-col md:mb-7 mb-3">
                <div className="text-16px py-[6px] font-bold">{item.title}</div>
                <div className="flex py-[6px] justify-between items-center">
                  <span className="text-14px max-w-[192px]">{item.desc}</span>
                  <img
                    onClick={() => handleCopySample(item.desc)}
                    width={24}
                    height={24}
                    src={IconCopyBlack}
                  />
                </div>
              </div>
            ))}
          </div>
        </Sider>
        <Layout className="layout-scrapegraph-content h-full min-h-screen">
          <Header
            className={classNames(
              "bg-[#f1f3ff] md:py-3 md:px-8 px-3",
              "flex justify-between items-center",
              {
                hidden: !accountInfo,
              }
            )}
          >
            <div className="text-purple font-bold text-24px">
              Hello{accountInfo?.name ? `, ${accountInfo?.name}!` : "!"}
            </div>
            <Dropdown menu={{ items }}>
              <div
                className={classNames(
                  "rounded-full w-10 h-10  cursor-pointer",
                  {
                    hidden: !accountInfo?.avatar,
                  }
                )}
              >
                <img
                  width={40}
                  height={40}
                  className="rounded-full"
                  src={accountInfo?.avatar}
                />
              </div>
            </Dropdown>
          </Header>
          <Content
            className={classNames("bg-[#f1f3ff] min-h-full grow lg:p-8 p-0")}
          >
            <ScrapegraphMainForm />
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default LayoutScrapegraphContainer;
