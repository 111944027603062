import * as React from "react";
import "./style.css";
import { TooltipPlacement } from "antd/lib/tooltip";
import { ReactComponent as IconHelp } from "../../../assets/icon/ic-help.svg";
import { Tooltip } from "antd";

export interface IToolTipHoverProps {
  className?: string;
  children?: React.ReactNode;
  iconHover?: React.ReactNode;
  paddingIcon?: string;
  position?: TooltipPlacement | undefined;
}

export function ToolTipHover({
  className,
  children,
  paddingIcon,
  iconHover = <IconHelp className="w-5 h-5" />,
  position = "top",
}: IToolTipHoverProps) {
  const iconToolStyle: React.CSSProperties = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: paddingIcon || "0 0 0 4px",
  };

  return (
    <Tooltip
      rootClassName={className}
      placement={position}
      title={children}
      overlayClassName="tooltip-hover"
      trigger={["hover", "focus", "click"]}
    >
      <div style={iconToolStyle}>{iconHover}</div>
    </Tooltip>
  );
}
