import { atom, selector } from "recoil";
import MedalService from "../../services/medal";
import UserService from "../../services/user";

export const listMedalSelector = selector({
  key: "listMedalSelector",
  get: async () => {
    let res = await MedalService.getAll();
    if (res.code === 0) {
      return res.data;
    }
    return [];
  },
});

export const listUserMedalSelector = selector({
  key: "listUserMedalSelector",
  get: async () => {
    let res = await UserService.getUserMedal();
    if (res.code === 0) {
      return {
        list: res.data,
        total: res.total * 1,
      };
    }
    return {
      list: [],
      total: 0,
    };
  },
});
