export enum PROTOTYPE_SCRAPEGRAPH {
  TYPE1 = 1,
  TYPE2 = 2,
  TYPE3 = 3,
}

export const MAP_PROTOTYPE_INFO = {
  [PROTOTYPE_SCRAPEGRAPH.TYPE1]: {
    text: `Images collected: 7546`,
    cta: "Download images",
    link: "https://cdn.nodepay.org/sc/Fruit+Ripening+Process.v2i.multiclass.zip",
    fileName: "FruitRipeningProcess.v2i.multiclass.zip",
  },
  [PROTOTYPE_SCRAPEGRAPH.TYPE2]: {
    text: `Record collected: 185`,
    cta: "Download this CSV",
    link: "https://cdn.nodepay.org/sc/Financial+Statements.csv",
    fileName: "FinancialStatements.csv",
  },
  [PROTOTYPE_SCRAPEGRAPH.TYPE3]: {
    text: `Record collected: 185`,
    cta: "Download this CSV",
    link: "https://cdn.nodepay.org/sc/sc_books.csv",
    fileName: "sc_books.csv",
  },
};
