import classNames from "classnames";
import { TYPE_DEVICE } from "../../../constants";

const BannerContainer = ({ hidden, children, typeDevice }) => {
  return (
    <div
      className={classNames(
        "px-2 bg-purple min-h-[56px] h-fit flex items-center justify-center",
        { hidden: hidden, "max-w-[576px]": typeDevice === TYPE_DEVICE.TABLET }
      )}
    >
      {children}
    </div>
  );
};

export default BannerContainer;
