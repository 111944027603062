export enum ACCOUNT_TYPE {
  ACTIVE = "ACTIVE",
  INACTIVE = "UNVERIFIED",
}

export enum CAMPAIGN_STATE {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum TRANSACTION_TYPE {
  ALL = -1,
  REFERRAL = "REFERRAL",
  ACTIVITY = "ACTIVITY",
  EARNING = "EARNING",
  PARTNER_REWARD = "PARTNER_REWARD",
  MOBILE_REWARD = "MOBILE_REWARD",
}

export enum MEDAL_TYPE {
  NOT_READY_TO_CLAIM = "NOT_READY_TO_CLAIM",
  CLAIMED = "CLAIMED",
  READY_TO_CLAIM = "READY_TO_CLAIM",
}

export enum REFERRAL_PROGRAM_STATUS_TYPE {
  ALL = -1,
  SUCCESS = "SUCCESS",
  PENDING = "PENDING",
}

export enum KIND_TYPE {
  NONE = "NONE",
  ADD = "ADD",
  SUBTRACT = "SUBTRACT",
}

export enum NETWORK_STATUS {
  DISCONNECTED = 0,
  CONNECTED = 1,
}

export enum NETWORK_QUALITY {
  DISCONNECTED = 0,
  HIGH = 1,
  MEDIUM = 2,
  LOW = 3,
}

export enum MISSION_TYPE {
  CHECKIN = "CHECKIN",
  SOCIAL_ENGAGEMENT = "SOCIAL_ENGAGEMENT",
  SURVEY_MISSION = "SURVEY_MISSION",
  NETWORK_CONNECTION = "NETWORK_CONNECTION",
  REFERRAL_MISSION = "REFERRAL_MISSION",
  CONSECUTIVE_CHECKIN = "CONSECUTIVE_CHECKIN",
}

export enum MISSION_CODE {
  "DAILY_CHECKIN" = "DAILY_CHECKIN",
  "SEVEN_DAY_CHECKIN" = "SEVEN_DAY_CHECKIN",
  "VERIFY_DISCORD" = "VERIFY_DISCORD",
  "VERIFY_X" = "VERIFY_X",
  "VERIFY_EMAIL" = "VERIFY_EMAIL",
  "FOLLOW_X" = "FOLLOW_X",
  "JOIN_DISCORD" = "JOIN_DISCORD",
  "QNA_CHALLENGE_SURVEY" = "QNA_CHALLENGE_SURVEY",
  "QNA_CHALLENGE_SURVEY_SET_2" = "QNA_CHALLENGE_SURVEY_SET_2",
  "QNA_CHALLENGE_SURVEY_SET_3" = "QNA_CHALLENGE_SURVEY_SET_3",
  "REFERRAL_PRO" = "REFERRAL_PRO",
  "FIRST_SUCCESS_REFERRAL" = "FIRST_SUCCESS_REFERRAL",
  "REFERRAL_MASTER" = "REFERRAL_MASTER",
  "REFERRAL_EXPERT" = "REFERRAL_EXPERT",
  "REFERRAL_LEGEND" = "REFERRAL_LEGEND",
  "NETWORK_24_HOURS_CONNECTED" = "NETWORK_24_HOURS_CONNECTED",
  "SEVEN_DAYS_CONSECUTIVE_CHECKIN_STREAK" = "SEVEN_DAYS_CONSECUTIVE_CHECKIN_STREAK",
  "FOURTEEN_DAYS_CONSECUTIVE_CHECKIN_STREAK" = "FOURTEEN_DAYS_CONSECUTIVE_CHECKIN_STREAK",
  "TWENTY_ONE_DAYS_CONSECUTIVE_CHECKIN_STREAK" = "TWENTY_ONE_DAYS_CONSECUTIVE_CHECKIN_STREAK",
  "TWENTY_DAYS_CONSECUTIVE_CHECKIN_STREAK" = "TWENTY_DAYS_CONSECUTIVE_CHECKIN_STREAK",
}

export enum MISSION_STATUS {
  AVAILABLE = "AVAILABLE",
  LOCK = "LOCK",
  COMPLETED = "COMPLETED",
}

export enum AGE_ENUM {
  "UNDER_18",
  BETWEEN_18_24 = "BETWEEN_18_24",
  BETWEEN_25_34 = "BETWEEN_25_34",
  BETWEEN_35_44 = "BETWEEN_35_44",
  BETWEEN_45_54 = "BETWEEN_45_54",
  BETWEEN_55_64 = "BETWEEN_55_64",
  ABOVE_65 = "ABOVE_65",
}

export enum MISSION_STATUS_PARAM {
  SUCCESS = 1,
  FAIL = 2,
  ACCOUNT_EXIST = 3,
  USER_NOT_FOLLOW = 4, //For mission X
}

export enum MISSION_CODE_PARAM {
  VERIFY_DISCORD = "VERIFY_DISCORD",
  VERIFY_X = "VERIFY_X",
  JOIN_DISCORD = "JOIN_DISCORD",
  FOLLOW_X = "FOLLOW_X",
}

export enum SOCIAL_PROVIDER {
  DISCORD = "DISCORD",
  X = "X",
  EMAIL = "EMAIL",
}

export enum DISCORD_ROLE {
  NONE = "NONE",
  GENERAL_NODE = "GENERAL_NODE",
  NETWORK_NODE = "NETWORK_NODE",
  I_WAS_EARLY = "I_WAS_EARLY",
  NEW_FOUNDER = "NEW_FOUNDER",
  VETERAN_FOUNDER = "VETERAN_FOUNDER",
}

export enum REFERRAL_STATUS {
  SUCCESS = "SUCCESS",
  PENDING = "PENDING",
}

export enum REFERRAL_CAMPAIGN_STATUS {
  ACTIVE = "ACTIVE",
  COMING_SOON = "COMING_SOON",
  ENDED = "ENDED",
}

export enum EARNING_STATE {
  PENDING = "PENDING",
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
  CANCELLED = "CANCELLED",
}

export enum SEASON_EARNING_STATE {
  DELETED = "DELETED",
  ACTIVE = "ACTIVE",
  CALCULATING = "CALCULATING",
  REVIEWING = "REVIEWING",
  COMPLETE = "COMPLETED",
}

export enum BOOST_CAMPAIGN_STATUS {
  ALL = "ALL",
  INACTIVE = "INACTIVE",
  ACTIVE = "ACTIVE",
  COMING_SOON = "COMING_SOON",
  ENDED = "ENDED",
}

export enum WALLET_STATUS {
  VERIFY_EMAIL = "VERIFY_EMAIL",
  NOT_CONNECTED = "NOT_CONNECTED",
  VERIFY_WALLET = "VERIFY_WALLET",
  CONNECTED = "CONNECTED",
}

export enum WALLET_STEP {
  STEP1 = 1,
  STEP2 = 2,
  STEP3 = 3,
}

export enum WALLET_CTA {
  DONE = 1,
  DISABLED = 2,
  ACTIVE = 3,
}
