import classNames from "classnames";
import React from "react";

interface IBoxContainer {
  title: string;
  children?: any;
  className?: string;
}
const BoxContainer = ({ title, children, className }: IBoxContainer) => {
  return (
    <div
      className={classNames(
        "rounded-lg bg-white lg:py-6 lg:px-8 p-4 lg:max-w-[480px] h-fit w-full",
        className
      )}
    >
      <div className="lg:mb-3 mb-2" hidden={!title}>
        <span className="lg:text-32px  text-24px">{title}</span>
      </div>
      {children}
    </div>
  );
};

export default BoxContainer;
