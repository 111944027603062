import { Layout, Menu, Modal } from "antd";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useRecoilRefresher_UNSTABLE,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import {
  accountState,
  infoSocialState,
  isInTimeMaintain,
  tokenState,
} from "../../adapter/recoil/common/state";
import { IconCloseModal, IconCloseModalWhite } from "../../assets/icon";
import { ReactComponent as IconLogo } from "../../assets/icon/ic-logo-pc.svg";
import { ReactComponent as IconLogout } from "../../assets/icon/ic-logout.svg";
import { ReactComponent as IcMenuHot } from "../../assets/icon/ic-menu-hot.svg";
import { ReactComponent as IconNext } from "../../assets/icon/ic-next.svg";
import { TYPE_DEVICE } from "../../constants";
import ROUTE from "../../constants/route";
import Banner from "../components/Banner";
import { MAP_MENU, MENU_TYPE } from "./menu_props";
import ModalPopupTelegram from "./ModalPopupTelegram";
import ModalPopupX from "./ModalPopupX";
import ModalUserProfile from "./ModalUserProfile";

const { Header, Content, Footer, Sider } = Layout;

interface ILayoutAuthContainer {
  classNameHeader?: string;
  classNameContent?: string;
  children?: any;
}
const LayoutContainer = ({
  children,
  classNameHeader,
  classNameContent = "",
}: ILayoutAuthContainer) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [accountInfo, setAccountInfo] = useRecoilState(accountState);
  const [isModalProfileOpen, setIsModalProfileOpen] = useState(false);
  const [isModalPopupX, setIsModalPopupX] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const isInTimeMaintainance = useRecoilValue(isInTimeMaintain);
  const setToken = useSetRecoilState(tokenState);
  const refreshSocialInfo = useRecoilRefresher_UNSTABLE(infoSocialState);
  const [isModalPopupTelegram, setIsModalPopupTelegram] = useState(false);

  useEffect(() => {
    if (accountInfo) {
      let triggerShowX = localStorage.getItem(
        `np_trigger_${accountInfo?.uid}_x`
      );
      if (!triggerShowX) {
        setIsModalPopupX(true);
      }
      // let triggerShowTele = localStorage.getItem(
      //   `np_trigger_${accountInfo?.uid}_tele`
      // );
      // if (!triggerShowTele) {
      //   setIsModalPopupTelegram(true);
      // }
    }
  }, [accountInfo]);
  const renderIconMenu = (menuType) => {
    return (
      <>
        <img
          width={24}
          height={24}
          className="img-menu-active"
          src={MAP_MENU[menuType]?.active}
        />
        <img
          width={24}
          height={24}
          className="img-menu-inactive"
          src={MAP_MENU[menuType]?.inactive}
        />
      </>
    );
  };
  const handleLogout = () => {
    navigate(ROUTE.LOGIN);
    navigate(0);
    localStorage.removeItem("np_token");
    localStorage.removeItem("np_webapp_token");
    sessionStorage.removeItem("np_webapp_token");
    setAccountInfo(undefined);
    setToken(undefined);
  };

  const renderDefaultKeys = (path) => {
    let keys = [];
    switch (path) {
      case ROUTE.BOOST_CAMPAIGN:
        keys = [MENU_TYPE.MEDAL];
        break;
      case ROUTE.REFERRAL:
        keys = [MENU_TYPE.REFERRAL];
        break;
      case ROUTE.TRANSACTION_HISTORY:
        keys = [MENU_TYPE.TRANSACTION_HISTORY];
        break;
      case ROUTE.EARNING:
        keys = [MENU_TYPE.EARNING];
        break;
      case ROUTE.MISSIONS_REWARD:
        keys = [MENU_TYPE.REWARD];
        break;
      case ROUTE.MEDAL:
        keys = [MENU_TYPE.MEDAL];
        break;
      default:
        keys = [MENU_TYPE.DASHBOARD];
    }
    setSelectedKeys(keys);
    return keys;
  };

  useEffect(() => {
    renderDefaultKeys(location.pathname);
  }, [location.pathname]);

  return (
    <>
      {isInTimeMaintainance ? (
        <div
          className={classNames(
            "bg-[#FDF77D] w-screen h-[56px] flex items-center justify-center"
          )}
        >
          <span className="text-neutral-110 font-bold">
            We apologize for the inconvenience caused by maintenance. Enjoy a
            voucher code 'compensation' as our token of gratitude for your
            patience.
          </span>
        </div>
      ) : (
        <Banner accountInfo={accountInfo} typeDevice={TYPE_DEVICE.DESKTOP} />
      )}

      <Layout className="flex flex-col h-full">
        <Sider
          className="sider-menu"
          collapsed={false}
          theme="light"
          width={280}
          hidden={
            ![
              ROUTE.DASHBOARD,
              ROUTE.REFERRAL,
              ROUTE.TRANSACTION_HISTORY,
              ROUTE.MISSIONS_REWARD,
              ROUTE.MEDAL,
              ROUTE.EARNING,
              ROUTE.BOOST_CAMPAIGN,
            ].includes(location?.pathname)
          }
        >
          <div className="w-full h-20 py-[25px] pl-5">
            {/* <img className="w-[183px] h-[30px]" src={ImgLogo} alt="logo" /> */}
            <IconLogo />
          </div>
          <div className="flex flex-col justify-between h-full pt-8 pr-5 pb-7 pl-5 menu">
            <Menu
              className="w-[240px] border-none "
              theme="light"
              mode="inline"
              style={{
                borderInlineEnd: 0,
              }}
              selectedKeys={selectedKeys}
              onSelect={({ key }) => {
                navigate(MAP_MENU[key]?.path);
              }}
            >
              <Menu.Item
                icon={renderIconMenu(MENU_TYPE.DASHBOARD)}
                key={1}
                style={{
                  paddingRight: "16px",
                  paddingLeft: "16px",
                  marginTop: "0",
                }}
              >
                Dashboard
              </Menu.Item>
              <Menu.Item
                icon={renderIconMenu(MENU_TYPE.REFERRAL)}
                key={2}
                style={{ paddingRight: "16px", paddingLeft: "16px" }}
              >
                <div className="flex items-center">Referral Program</div>
              </Menu.Item>
              <Menu.Item
                icon={renderIconMenu(MENU_TYPE.REWARD)}
                key={3}
                style={{
                  paddingRight: "16px",
                  paddingLeft: "16px",
                }}
              >
                <div className="flex items-center">Missions & Rewards</div>
              </Menu.Item>
              <Menu.Item
                icon={renderIconMenu(MENU_TYPE.EARNING)}
                key={6}
                style={{
                  paddingRight: "16px",
                  paddingLeft: "16px",
                  position: "relative",
                }}
              >
                <div className="flex items-center">
                  Earnings
                  <IcMenuHot className=" ml-2 min-w-[18px] min-h-[22px]" />
                </div>
              </Menu.Item>
              <Menu.Item
                icon={renderIconMenu(MENU_TYPE.MEDAL)}
                key={4}
                style={{ paddingRight: "16px", paddingLeft: "16px" }}
              >
                <div className="flex items-center">Medal & Boost</div>
              </Menu.Item>
              <Menu.Item
                icon={renderIconMenu(MENU_TYPE.TRANSACTION_HISTORY)}
                key={5}
                style={{ paddingRight: "16px", paddingLeft: "16px" }}
              >
                <div className="flex items-center">Transaction History</div>
              </Menu.Item>
            </Menu>
            <div className="sticky bottom-[28px] left-0">
              <div className="rounded-lg p-4 border border-solid border-neutral-110 bg-neutral-10">
                <div className="text-14px font-bold text-purple-primary mb-[10px]">
                  Learn how to optimize your experience
                </div>
                <div
                  className="flex justify-between items-center cursor-pointer"
                  onClick={() =>
                    window.open("https://nodepay.ai/#blogs", "_blank")
                  }
                >
                  <div className="text-neutral-110">Read Blog</div>
                  <div className="w-7 h-7 rounded-lg ant-btn-primary flex items-center justify-center">
                    <IconNext />
                  </div>
                </div>
              </div>
              <div
                className="flex items-center pl-4 cursor-pointer mt-4 h-[56px] btn-logout"
                onClick={handleLogout}
              >
                <IconLogout />
                <span className="mr-2 text-16px">Log out</span>
              </div>
            </div>
          </div>
        </Sider>
        <Layout className="h-full min-h-screen">
          <Header
            className={classNames(
              "bg-[#f1f3ff] py-3 px-8",
              "flex justify-between items-center",
              {
                hidden: !accountInfo,
              }
            )}
          >
            <div className="text-purple font-bold text-24px">
              Hello{accountInfo?.name ? `, ${accountInfo?.name}!` : "!"}
            </div>
            <div
              className={classNames(
                "rounded-full w-10 h-10  cursor-pointer z-50",
                {
                  hidden: !accountInfo?.avatar,
                }
              )}
              onClick={() => {
                if (accountInfo) {
                  setIsModalProfileOpen(true);
                  refreshSocialInfo();
                }
              }}
            >
              <img
                width={40}
                height={40}
                className="rounded-full"
                src={accountInfo?.avatar}
              />
            </div>
          </Header>
          <Content
            className={classNames(
              "bg-[#f1f3ff] min-h-full grow p-8",
              classNameContent
            )}
          >
            {children}
          </Content>
        </Layout>
      </Layout>
      <Modal
        title="User Profile"
        open={isModalProfileOpen}
        closeIcon={<img width={24} height={24} src={IconCloseModal} />}
        className="modal-profile-user"
        //onOk={handleOk}
        onCancel={() => setIsModalProfileOpen(false)}
        footer={null}
        width={600}
      >
        <ModalUserProfile
          setIsModalProfileOpen={setIsModalProfileOpen}
          accountInfo={accountInfo}
          handleLogout={handleLogout}
        />
      </Modal>
      <Modal
        title=""
        open={isModalPopupX}
        closeIcon={<img width={24} height={24} src={IconCloseModalWhite} />}
        className="modal-popup-x w-[460px] h-[347px]"
        footer={null}
        onCancel={() => {
          localStorage.setItem(`np_trigger_${accountInfo?.uid}_x`, "checked");
          setIsModalPopupX(false);
        }}
      >
        <ModalPopupX typeDevide={TYPE_DEVICE.DESKTOP}></ModalPopupX>
      </Modal>
      <Modal
        title=""
        width={500}
        open={isModalPopupTelegram}
        closeIcon={<img width={24} height={24} src={IconCloseModalWhite} />}
        className="modal-popup-x"
        footer={null}
        onCancel={() => {
          localStorage.setItem(
            `np_trigger_${accountInfo?.uid}_tele`,
            "checked"
          );
          setIsModalPopupTelegram(false);
        }}
      >
        <ModalPopupTelegram
          typeDevide={TYPE_DEVICE.DESKTOP}
        ></ModalPopupTelegram>
      </Modal>
    </>
  );
};

export default LayoutContainer;
