import API from "../../constants/api";
import { getWithPath } from "../http";

const UserService = {
  getUserSocial: () => {
    return getWithPath(API.USER.GET.SOCIAL);
  },
  getUserMedal: () => {
    return getWithPath(API.USER.GET.MEDAL);
  },
};
export default UserService;
