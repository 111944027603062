import { atom, selector } from "recoil";
import BannerService from "../../services/banner";

export const earnInfoState = atom({
  key: "earnInfoState",
  default: undefined,
});

export const bannerSliderDashboardState = atom({
  key: "bannerSliderDashboardState",
  default: selector({
    key: "bannerSliderSelector",
    get: async () => {
      let res = await BannerService.getList({ type: "DASHBOARD_HEADER" });
      if (res.code === 0) {
        return res.data;
      }
      return;
    },
  }),
});
