import { ConfigProvider, Spin } from "antd";
import { Suspense, lazy, useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { setRecoil } from "recoil-nexus";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { buildURLWithParam } from "./adapter";
import { tokenState, typeViewState } from "./adapter/recoil/common/state";
import "./App.css";
import { TYPE_DEVICE, WIDTH_DEVICE } from "./constants";
import ROUTE from "./constants/route";
import useCurrentWidth from "./shared/hook/useCurrentWidth";
import LayoutAuthContainer from "./shared/layout/LayoutAuth";
import LayoutScrapegraphContainer from "./shared/layout/LayoutScapegraph";
import { ProtectedRoute } from "./shared/protectedRoute";
import DownloadView from "./module/download/views";
const _404View = lazy(() => import("./module/404/views"));
const RegisterView = lazy(() => import("./module/account/register/views"));
const LoginView = lazy(() => import("./module/account/login/views"));
const ForgotPasswordView = lazy(
  () => import("./module/account/forgot-password/views")
);
const CreateNewPasswordView = lazy(
  () => import("./module/account/create-new-password/views")
);

const RegisterResultView = lazy(
  () => import("./module/account/register/views/result")
);

const DashboardView = lazy(() => import("./module/dashboard/views/index"));
const ReferralView = lazy(() => import("./module/referral/views/index"));
const BoostCampaignView = lazy(() => import("./module/boost/views"));
const VerifyWalletConnectionView = lazy(
  () => import("./module/earnings/views/components/VerifyWalletConnectionView")
);
const EarningsView = lazy(() => import("./module/earnings/views/container"));
const MedalView = lazy(() => import("./module/medal/views"));
const VerifyEmailView = lazy(() => import("./module/missions/verify-email"));
const MissionView = lazy(() => import("./module/missions/views/index"));
const OauthPermissionView = lazy(() => import("./module/oauth/views"));
const TransactionHistoryView = lazy(
  () => import("./module/transactions/views")
);

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [token, setToken] = useRecoilState(tokenState);
  const screenWidth = useCurrentWidth();
  useEffect(() => {
    let tokenInfo =
      sessionStorage.getItem("np_webapp_token") ||
      localStorage.getItem("np_webapp_token");
    if (tokenInfo) {
      setToken(tokenInfo);
    }
    // redirect to default page
    if (window.location.pathname === "/") {
      navigate(ROUTE.DASHBOARD);
    }
  }, []);

  useEffect(() => {
    let type =
      screenWidth > WIDTH_DEVICE.TABLET
        ? TYPE_DEVICE.DESKTOP
        : screenWidth > WIDTH_DEVICE.MOBILE
        ? TYPE_DEVICE.TABLET
        : TYPE_DEVICE.MOBILE;
    setRecoil(typeViewState, type);
  }, [screenWidth]);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#431ECF",
        },
        components: {
          Button: {
            fontWeight: 700,
            paddingBlock: 10,
            contentFontSizeLG: 14,
          },
          Switch: {
            colorPrimary: "#000",
            colorPrimaryHover: "#000",
          },
          Input: {
            fontSizeLG: 14,
            fontSize: 14,
          },
          Checkbox: {
            colorPrimary: "#000",
            colorPrimaryHover: "#000",
          },
          Layout: {
            lightSiderBg: "#FFFFFF",
          },
          Menu: {
            colorBgContainer: "#FFFFFF",
            itemColor: "#161A27",
            itemSelectedBg: "#16085F",
            itemSelectedColor: "white",
            itemDisabledColor: "#A2A3A9",
            itemHeight: 48,
            fontSize: 16,
            itemPaddingInline: 12,
            itemMarginBlock: 8,
          },
          Form: {
            itemMarginBottom: 12,
          },
          Table: {
            borderColor: "#E4E5E9",
            headerColor: "#161A27",
            headerBg: "#F5F5F8",
          },
          Select: {
            optionSelectedBg: "#F5F5F8",
            optionSelectedColor: "#161A27",
            optionSelectedFontWeight: 400,
          },
          DatePicker: {
            activeBorderColor: "#593FDE",
            activeShadow: "0px 0px 0px 2px #9747FF40",
            hoverBorderColor: "#593FDE",
            paddingBlockLG: 8,
            paddingInline: 16,
            inputFontSize: 14,
            inputFontSizeLG: 14,
            cellActiveWithRangeBg: "#EEECFC",
            addonBg: "yellow",
          },
          Tabs: {
            titleFontSizeSM: 16,
            titleFontSize: 16,
            itemActiveColor: "#16085F",
            inkBarColor: "#16085F",
            itemSelectedColor: "#16085F",
            itemHoverColor: "#16085F",
          },
        },
      }}
    >
      <Suspense fallback={<Spin />}>
        <Routes>
          <Route path="/" element={<LayoutAuthContainer />}></Route>
          <Route path={ROUTE.LOGIN} element={<LoginView />}></Route>
          <Route
            path={ROUTE.FORGOT_PASSWORD}
            element={<ForgotPasswordView />}
          ></Route>
          <Route
            path={ROUTE.CREATE_NEW_PASSWORD}
            element={<CreateNewPasswordView />}
          ></Route>
          <Route path={ROUTE.REGISTER} element={<RegisterView />}></Route>
          <Route
            path={ROUTE.BOOST_CAMPAIGN}
            element={
              <ProtectedRoute
                children={<BoostCampaignView />}
                redirectTo={ROUTE.LOGIN}
              />
            }
          ></Route>
          <Route
            path={ROUTE.REGISTER_RESULT}
            element={<RegisterResultView />}
          ></Route>
          <Route
            path={ROUTE.VERIFY_EMAIL}
            element={<VerifyEmailView />}
          ></Route>
          <Route
            path={ROUTE.VERIFY_WALLET}
            element={<VerifyWalletConnectionView />}
          ></Route>
          <Route
            path={ROUTE.DASHBOARD}
            element={
              <ProtectedRoute
                children={<DashboardView />}
                redirectTo={ROUTE.LOGIN}
              />
            }
          ></Route>
          <Route
            path={ROUTE.SCRAPEGRAPH}
            element={
              <ProtectedRoute
                children={<LayoutScrapegraphContainer />}
                redirectTo={ROUTE.LOGIN}
              />
            }
          ></Route>
          <Route
            path={ROUTE.REFERRAL}
            element={
              <ProtectedRoute
                children={<ReferralView />}
                redirectTo={ROUTE.LOGIN}
              />
            }
          />
          <Route
            path={ROUTE.TRANSACTION_HISTORY}
            element={
              <ProtectedRoute
                children={<TransactionHistoryView />}
                redirectTo={ROUTE.LOGIN}
              />
            }
          />
          <Route
            path={ROUTE.MEDAL}
            element={
              <ProtectedRoute
                children={<MedalView />}
                redirectTo={ROUTE.LOGIN}
              />
            }
          />
          <Route
            path={ROUTE.EARNING}
            element={
              <ProtectedRoute
                children={<EarningsView />}
                redirectTo={ROUTE.LOGIN}
              />
            }
          />
          <Route
            path={ROUTE.MISSIONS_REWARD}
            element={
              <ProtectedRoute
                children={<MissionView />}
                redirectTo={ROUTE.LOGIN}
              />
            }
          />
          <Route
            path={ROUTE.OAUTH_PERMISSION}
            element={
              <ProtectedRoute
                children={<OauthPermissionView />}
                redirectTo={buildURLWithParam(ROUTE.LOGIN, {
                  continue: window.location.href,
                })}
              />
            }
          />
          <Route path={ROUTE.DOWNLOAD_APP} element={<DownloadView />} />
          <Route path={"/*"} element={<_404View />}></Route>
        </Routes>
      </Suspense>
    </ConfigProvider>
  );
}

export default App;
