import React, { useMemo } from "react";
import { ToolTipHover } from "../../../../../shared/components/TooltipHover";
import { IconHelpBorder, IconWalletCheck } from "../../../../../assets/icon";
import {
  WALLET_CTA,
  WALLET_STATUS,
  WALLET_STEP,
} from "../../../../../constants/enum";
import { MAP_WALLET_TEXT, TYPE_DEVICE } from "../../../../../constants";
import useCurrentWidth from "../../../../../shared/hook/useCurrentWidth";
import { useRecoilValue } from "recoil";
import { typeViewState } from "../../../../../adapter/recoil/common/state";

interface IWalletStep {
  img: string;
  tooltip: string;
  title: string;
  subTitle?: any;
  handleClick?: any;
  disabled?: boolean;
  step: WALLET_STEP;
  status?: WALLET_STATUS;
}

const WalletConnectionDone = ({ text }) => {
  const screenWidth = useCurrentWidth();

  const isShowText = useMemo(() => {
    if (screenWidth <= 1400) {
      return false;
    } else return true;
  }, [screenWidth]);

  return (
    <div className="h-8 px-2 rounded-[24px] gap-1 flex items-center text-white bg-[#58CC02] font-bold">
      <img className="w-5 h-5" src={IconWalletCheck} />
      {isShowText && text}
    </div>
  );
};

const DisabledCTA = ({ ctaText }) => {
  return (
    <div
      className="shrink-0 p-[10px] rounded-[100px] bg-grey-30"
      style={{
        background:
          "linear-gradient(183deg, rgba(165, 235, 255, 0.20) 43.82%, rgba(165, 235, 255, 0.00) 100%)",
      }}
    >
      <div className="w-20 h-[34px] px-3 flex items-center justify-center text-neutral-60 text-12px font-bold bg-white rounded-[100px]">
        {ctaText}
      </div>
    </div>
  );
};

const ActiveCTA = ({ ctaText, handleClick }) => {
  return (
    <div
      className="shrink-0 p-[10px] rounded-[100px] cursor-pointer"
      style={{
        background:
          "linear-gradient(183deg, rgba(165, 235, 255, 0.20) 43.82%, rgba(165, 235, 255, 0.00) 100%)",
      }}
      onClick={handleClick}
    >
      <div className="w-20 h-[34px] px-3 flex items-center justify-center text-neutral-110 text-12px font-bold bg-white rounded-[100px]">
        {ctaText}
      </div>
    </div>
  );
};
const WalletStep = ({
  img,
  tooltip,
  title,
  subTitle,
  handleClick,
  step,
  status,
}: IWalletStep) => {
  const renderCTA = () => {
    let component;
    switch (status) {
      case WALLET_STATUS.VERIFY_EMAIL:
        component =
          step == WALLET_STEP.STEP1 ? WALLET_CTA.ACTIVE : WALLET_CTA.DISABLED;
        break;
      case WALLET_STATUS.NOT_CONNECTED:
        component =
          step == WALLET_STEP.STEP1
            ? WALLET_CTA.DONE
            : step == WALLET_STEP.STEP2
            ? WALLET_CTA.ACTIVE
            : WALLET_CTA.DISABLED;
        break;
      case WALLET_STATUS.VERIFY_WALLET:
        component =
          step == WALLET_STEP.STEP3 ? WALLET_CTA.ACTIVE : WALLET_CTA.DONE;
        break;
      case WALLET_STATUS.CONNECTED:
        component = WALLET_CTA.DONE;
        break;
      default:
        break;
    }
    if (component === WALLET_CTA.ACTIVE) {
      return (
        <ActiveCTA
          handleClick={handleClick}
          ctaText={MAP_WALLET_TEXT.ACTION[step]}
        />
      );
    } else if (component === WALLET_CTA.DISABLED) {
      return <DisabledCTA ctaText={MAP_WALLET_TEXT.ACTION[step]} />;
    } else if (component == WALLET_CTA.DONE) {
      return <WalletConnectionDone text={MAP_WALLET_TEXT.DONE[step]} />;
    }
    return <></>;
  };
  return (
    <div
      className="lg:w-1/3 w-full rounded-2xl px-3 py-4 h-[86px] flex items-center"
      style={{
        border: "2px solid #6547FF",
        background:
          "linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%), linear-gradient(317deg, #121315 17.1%, #1F1B3B 43.34%, #462FBD 97.69%)",
      }}
    >
      <div className="grow flex items-center gap-2">
        <img className="w-10 h-10 object-cover" src={img} />
        <div className="text-white">
          <div className="flex">
            {title}
            <div className="h-fit">
              <ToolTipHover
                iconHover={
                  <img
                    width={20}
                    height={20}
                    className="object-cover cursor-pointer"
                    src={IconHelpBorder}
                  />
                }
              >
                {tooltip}
              </ToolTipHover>
            </div>
          </div>
          <div className="text-12px">{subTitle}</div>
        </div>
      </div>
      {renderCTA()}
    </div>
  );
};

export default WalletStep;
