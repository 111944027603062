import { Button } from "antd";
import dayjs from "dayjs";
import { useState } from "react";
import toastFn from "../../../../../shared/components/ToastNoti";
import WalletService from "../../../../../adapter/services/wallet";

const ModalConfirmEmail = ({ wallet_address, email }) => {
  const [resendModal, setResendModal] = useState<boolean>(false);
  const [disabledBtnSend, setDisabledBtnSend] = useState<boolean>(true);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);

  const handleSendEmail = () => {
    WalletService.sendVerifyWallet({ wallet_address: wallet_address })
      .then((res) => {
        if (res.code !== 0) {
          toastFn({ message: "Error", description: res.msg });
        }
      })
      .catch((error) => {
        toastFn({
          message: "Error",
          description: error?.msg || "Something went error",
        });
      });
    setResendModal(true);
    handleCountDown();
  };

  const handleResendEmail = () => {
    setLoadingSubmit(true);
    setDisabledBtnSend(true);
    handleCountDown();
    WalletService.sendVerifyWallet({ wallet_address: wallet_address })
      .then((res) => {
        if (res.code !== 0) {
          toastFn({ message: "Error", description: res.msg });
        }
      })
      .catch((error) => {
        toastFn({
          message: "Error",
          description: error?.msg || "Something went error",
        });
      })
      .finally(() => {
        setLoadingSubmit(false);
        setDisabledBtnSend(false);
      });
  };

  const handleCountDown = () => {
    var countDownDate = new Date(
      dayjs(new Date()).add(3, "m").toDate()
    ).getTime();

    // Update the count down every 1 second
    var x = setInterval(function () {
      var now = new Date().getTime();

      var distance = countDownDate - now;

      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);

      document.getElementById("resend-confirm-wallet").innerHTML =
        minutes + ":" + seconds + ".";

      if (distance < 0) {
        clearInterval(x);
        setDisabledBtnSend(false);
        document.getElementById("resend-confirm-wallet").innerHTML = "0:00";
      }
    }, 1000);
  };
  return (
    <div className="flex flex-col gap-8 w-full mt-6">
      <div className="text-neutral-110 font-bold lg:text-24px text-18px">
        Confirm Wallet Connection
      </div>
      <div className="text-16px">
        After wallet confirmation, there is no way to change the wallet address
        connected to your account. It is your responsibility to ensure that your
        chosen wallet is correct.
      </div>
      <div className="text-16px px-4 py-3 rounded-lg bg-[#F2F4F8] text-neutral-70">
        Email with the link will be sent to{" "}
        <span className="text-neutral-110">{email}</span>
      </div>

      {resendModal ? (
        <div>
          <Button
            className="mb-2"
            type="text"
            block
            size="large"
            disabled={disabledBtnSend}
            htmlType="submit"
            onClick={handleResendEmail}
            loading={loadingSubmit}
          >
            Resend Email
          </Button>
          <div className="text-neutral-70">
            You can use “Resend email” again in {" "}
            <span id="resend-confirm-wallet"></span>
          </div>
        </div>
      ) : (
        <Button
          type="text"
          block
          size="large"
          onClick={() => {
            handleSendEmail();
          }}
        >
          Send Email
        </Button>
      )}
    </div>
  );
};

export default ModalConfirmEmail;
