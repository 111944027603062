import API from "../../constants/api";
import { getWithPath } from "../http";

const EarnService = {
  getEarnInfo: () => {
    return getWithPath(API.EARN.GET.INFO, {});
  },
  getDailyStatistic: (params) => {
    return getWithPath(API.EARN.GET.DAILY_STATISTIC, params);
  },
};
export default EarnService;
